/* global document: true */
/* global localStorage:true */
import { call, put, takeEvery } from 'redux-saga/effects';
import _ from 'underscore';
import { purgeStoredState } from 'redux-persist';
import lockr from 'lockr';
import { browserHistory } from 'react-router';
import { asyncSessionStorage } from 'redux-persist/storages';
import localforage from 'localforage';
import alertify from 'alertify.js';

import { fetchApi, fetchApiAuth } from '../utils/api';

import {
    deleteTokenSuccess,
    saveTokenSuccess,

    signupSuccess,
    signupFailure,

    sessionTokenSuccess,
    sessionTokenFailure,

    loginSuccess,
    loginFailure,

    logoutSuccess,

    apiLoginSuccess,

    contactSuccess,
    contactFailure,

    passwordResetSuccess,
    passwordResetFailure,

    forgotPasswordValidLinkSuccess,
    forgotPasswordValidLinkFailure,

    resetUserPasswordSuccess,
    resetUserPasswordFailure,

    userSettingsSuccess,
    userSettingsFailure,

    firstTimeLoginSuccess,
    firstTimeLoginFailure,
} from './userActions';

const {
    AUTH_KEY,
    API_LOGIN_KEY,
    REGISTER_AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
    LOCALSTORAGE_EXPIRES,
} = require('../constants').default;

const {
    LOGOUT_REQUEST,
    SIGNUP_REQUEST,
    SESSION_TOKEN_REQUEST,
    LOGIN_REQUEST,
    API_LOGIN_REQUEST,
    CONTACT_REQUEST,
    PASSWORD_RESET_REQUEST,
    FORGOT_PASSWORD_VALID_LINK_REQUEST,
    RESET_USER_PASSWORD_REQUEST,
    USER_SETTINGS_REQUEST,
    FIRST_TIME_LOGIN_REQUEST,
} = require('./userActions').constants;

function* deleteSessionToken() {
    lockr.rm(AUTH_KEY);
    lockr.rm(API_LOGIN_KEY);

    yield put(deleteTokenSuccess());
}

function* saveSessionToken(action) {
    const authDate = new Date();
    lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
    lockr.set(AUTH_KEY, action.data.auth_token);
    yield put(saveTokenSuccess());
}

function* logout() {
    yield call(deleteSessionToken);

    // remove state from local storage
    purgeStoredState({ storage: asyncSessionStorage });

    // go to login page
    setTimeout(() => browserHistory.push('/'), 200);

    // Clear everything in the store
    localforage.clear();
    yield put(logoutSuccess());
}

function* signup(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'post',
            url: '/auth/sign-up',
            body: action.payload,
        });

        yield call(saveSessionToken, response);
        yield put(signupSuccess(response));

        const page = '/dashboard';
        const timeOut = 500;

        setTimeout(() => browserHistory.push(page), timeOut);

        // load user data
        // yield put(getProfileRequest());
    } catch (e) {
        yield put(signupFailure(e.response ? e.response.data.message : e));
    }
}
function* checkUserLoggedIn() {
    let response = lockr.get(AUTH_KEY);

    if (_.isUndefined(response)) {
        const name = `${REGISTER_AUTH_KEY}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i += 1) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                response = c.substring(name.length, c.length);
                document.cookie = `${REGISTER_AUTH_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path/;`;
                break;
            }
        }
        const authDate = new Date();
        lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
        lockr.set(AUTH_KEY, response);
        yield put(saveTokenSuccess());
        response = lockr.get(AUTH_KEY);
    }

    if (!_.isUndefined(response)) {
        const authDate = lockr.get(LOCALSTORAGE_EXPIRES_KEY);

        if (!_.isUndefined(authDate)) {
            const aDate = new Date(authDate);
            const aNow = new Date();
            if (aNow < aDate) {
                aNow.setDate(aNow.getDate() + 1);
            }
            const milliseconds = aNow - aDate;
            const difference = Math.floor(milliseconds / 1000 / 60);
            if (difference >= LOCALSTORAGE_EXPIRES) {
                // lockr.remove(LOCALSTORAGE_EXPIRES_KEY);
                // lockr.remove(AUTH_KEY);
                alertify.error('Session expired. Please login again.');
                yield put(sessionTokenFailure('token not found'));
                purgeStoredState({ storage: asyncSessionStorage });
                localStorage.clear();
                // redirect to login page
                browserHistory.push('/');
            } else {
                yield put(sessionTokenSuccess(response));
            }
        } else {
            // lockr.remove(LOCALSTORAGE_EXPIRES_KEY);
            // lockr.remove(AUTH_KEY);
            alertify.error('Session expired. Please login again.');
            yield put(sessionTokenFailure('token not found'));
            purgeStoredState({ storage: asyncSessionStorage });
            localStorage.clear();
            // redirect to login page
            browserHistory.push('/');
        }

        // load user data
        // yield put(getProfileRequest());
    } else {
        yield put(sessionTokenFailure('token not found'));

        // redirect to login page
        browserHistory.push('/');
    }
}

function* login(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/login',
            body: { username: action.payload.username, password: action.payload.password },
        });

        yield call(saveSessionToken, response);
        yield put(loginSuccess(response));

        // load user data
        // yield put(getProfileRequest());

        const page = '/production';
        // const timeOut = 1;

        // navigate to dashboard
        // setTimeout(() => browserHistory.push(page), timeOut);
        // eslint-disable-next-line no-undef
        const params = new URLSearchParams(window.location.search);
        const redirectUrl = params.get('redirect');

        // If redirectUrl exists, navigate to it, otherwise go to the production page
        const path = redirectUrl ? decodeURIComponent(redirectUrl) : page;
        browserHistory.push(path);
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : 'Unknown error occured.');
        yield put(loginFailure(e.response ? e.response.data.message : e));
        setTimeout(() => browserHistory.push('/'), 500);
    }
}

/**
 * Used to login with TOKEN via API admin area
 * */
function* apiLogin(action) {
    try {
        const response = {
            data: action.payload,
        };

        yield call(saveSessionToken, response);
        yield put(loginSuccess(response));

        lockr.set(API_LOGIN_KEY, true);

        // navigate to dashboard
        browserHistory.push('/production');

        // load user data
        // yield put(getProfileRequest());

        yield put(apiLoginSuccess());
    } catch (e) {
        yield put(loginFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Used to contact
 * */
function* contact(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/auth/contact',
            body: action.payload,
        });

        yield put(contactSuccess(response));
        alertify.success('Message successfully sent');
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        yield put(contactFailure(e.response ? e.response.data.message : e));
    }
}

function* passwordReset(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/auth/reset-password',
            body: action.payload,
        });

        yield put(passwordResetSuccess(response));
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        yield put(passwordResetFailure(e.response ? e.response.data.message : e));
    }
}

function* forgotPasswordLinkValid(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'get',
            url: '/auth/validate-forgot-password-link',
            params: action.payload,
        });
        yield put(forgotPasswordValidLinkSuccess(response));
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        yield put(forgotPasswordValidLinkFailure(e.response ? e.response.data.message : e));
        browserHistory.push('/');
    }
}

function* resetUserPassword(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'post',
            url: '/auth/reset-user-password',
            body: action.payload,
        });
        yield put(resetUserPasswordSuccess(response));
        alertify.success('Password updated successfully.');
        browserHistory.push('/');
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        yield put(resetUserPasswordFailure(e.response ? e.response.data.message : e));
    }
}

function* settingsChangeRequest(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'post',
            url: '/auth/settings',
            body: action.payload,
        });
        yield put(userSettingsSuccess(response));
        alertify.success('Password updated successfully.');
        browserHistory.push('/production');
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        yield put(userSettingsFailure(e.response ? e.response.data.message : e));
    }
}

function* firstTimeLogin(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: `/login/${action.payload.loginKey}`,
        });

        yield call(saveSessionToken, response);
        yield put(firstTimeLoginSuccess(response));

        // load user data
        // yield put(getProfileRequest());

        const page = '/production';
        // const timeOut = 1;

        // navigate to dashboard
        // setTimeout(() => browserHistory.push(page), timeOut);
        browserHistory.push(page);
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : 'Unknown error occured.');
        yield put(firstTimeLoginFailure(e.response ? e.response.data.message : e));
        setTimeout(() => browserHistory.push('/'), 500);
    }
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield* [
        takeEvery(SESSION_TOKEN_REQUEST, checkUserLoggedIn),
        takeEvery(SIGNUP_REQUEST, signup),
        takeEvery(LOGIN_REQUEST, login),
        takeEvery(LOGOUT_REQUEST, logout),
        takeEvery(API_LOGIN_REQUEST, apiLogin),
        takeEvery(CONTACT_REQUEST, contact),
        takeEvery(PASSWORD_RESET_REQUEST, passwordReset),
        takeEvery(FORGOT_PASSWORD_VALID_LINK_REQUEST, forgotPasswordLinkValid),
        takeEvery(RESET_USER_PASSWORD_REQUEST, resetUserPassword),
        takeEvery(USER_SETTINGS_REQUEST, settingsChangeRequest),
        takeEvery(FIRST_TIME_LOGIN_REQUEST, firstTimeLogin),
    ];
}
