import axios from 'axios';
import lockr from 'lockr';
import _ from 'underscore';
// load mock data if enabled
import './mock';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
    API_URL,
    GOOGLE_MAPS_KEY,
} = require('../constants').default;

// defaults for axios
const api = axios.create({
    baseURL: API_URL,
});

api.interceptors.request.use((config) => {
    // Do something before request is sent
    // eslint-disable-next-line no-console
    console.log('%c Making Request:', 'color: #4CAF50; font-weight: bold', config);
    return config;
}, (error) => {
    // Do something with request error
    // eslint-disable-next-line no-console
    console.log('%c Request Error:', 'color: #EC6060; font-weight: bold', error);
    return Promise.reject(error);
});

export function fetchApi(opts, headers) {
    const data = (opts.method === 'get' || opts.method === 'GET') ? {} : opts.body;
    const responseType = (opts.responseType ? opts.responseType : 'json');
    return api({
        method: opts.method,
        url: opts.url,
        params: opts.params,
        data,
        headers,
        responseType,
    });
}

export function fetchApiAuth(opts) {
    return new Promise((resolve, reject) => {
        const authKey = lockr.get(AUTH_KEY);

        if (_.isUndefined(authKey)) {
            reject('not-authorised');
        } else {
            const authDate = new Date();
            lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
            const myOpts = opts;
            myOpts.params = myOpts.params || {};
            const headers = {};
            headers.Authorization = `Bearer ${authKey}`;

            resolve(fetchApi(opts, headers));
        }
    });
}

export function getAuthKey() {
    return lockr.get(AUTH_KEY);
}

export function locationData(name) {
    return axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${name}&key=${GOOGLE_MAPS_KEY}`);
}
