import React from 'react';
import {
    Row, Col, Icon, Input, Button
} from 'antd';
import { isEmpty } from 'underscore';
import CustomIcon from '../../elements/Icon';

import './imageCaptionArea.css';

export default function () {
    const { TextArea } = Input;
    const { caption } = this.state;
    const { imageCaptionModeOn, nextImage } = this.props;

    if (!imageCaptionModeOn) {
        return null;
    }

    return (
        <div className="image-caption-area-block">
            <div className="container">
                <Row type="flex" justify="space-around" align="middle">
                    <Col sm={12}>
                        <h3>
                            Caption
                            <Icon
                                type="message"
                                theme="filled"
                                style={{ marginLeft: '10px', color: '#52c41a' }} />
                        </h3>
                    </Col>
                    <Col style={{ textAlign: 'right' }} sm={12}>
                        <Button type="link" className="custom-link-button" icon="delete" onClick={() => this.setState({ caption: '' })} />
                        <Button type="link" className="custom-link-button ant-btn-icon-only" onClick={() => this.autoCaptionImage()}>
                            <CustomIcon name="magic-wand" />
                        </Button>
                        <Button type="link" className="custom-link-button" icon="history" onClick={() => this.loadCaptionHistory()} />
                    </Col>
                </Row>
                <Row>
                    <Col sm={24}>
                        <TextArea rows={4} onChange={(data) => this.setState({ caption: data.target.value })} value={caption} />
                    </Col>
                </Row>
                <Row>
                    <Col sm={24} className="button-col">
                        <Button onClick={() => this.saveImageCaption()}>
                            Save caption
                            {!isEmpty(nextImage) ? ' & continue >' : null}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
