import React from 'react';
import Helmet from 'react-helmet';
import { Spin } from 'antd';
import Loginform from '../../components/LoginForm';

export default function () {
    const { isFetching } = this.props;
    return (
        <Spin spinning={isFetching}>
            <div className="page-wrap login">
                <Helmet title="Login" />
                <Loginform isFetching={isFetching} />
            </div>
        </Spin>
    );
}
