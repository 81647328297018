/* global fetch, FileReader */
const convert2base64 = async (url) => {
    const blob = await fetch(url).then((r) => r.blob());
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        };
    });
};

export default convert2base64;
