import React from 'react';

import DOM from './requestAccess';

export default class RequestAccess extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    render() {
        return this.view();
    }
}
