import React from 'react';
import Helmet from 'react-helmet';
import {
    Tabs, Row, Col, Empty, Spin
} from 'antd';
import _ from 'underscore';
import ImagesList from '../../components/ImagesList';
import Menu from '../../components/Menu';
import ActiveDaySelector from '../../components/ActiveDaySelector';
import AllowanceWidget from '../../components/AllowanceWidget';
import { ROLE_ACTOR } from '../../core/utils/value';

const { TabPane } = Tabs;

export default function () {
    const {
        user, activeTab, imagesListPosition, isFetching, selectedProduction
    } = this.props;
    const {
        type, selectedKilledDay, killedDays, unkilledDays, selectedUnkilledDay
    } = this.state;
    let currentUser = {};
    if (!isFetching && !_.isEmpty(killedDays)) {
        currentUser = selectedProduction.currentUser;
    }

    return (
        <div className="page-wrap selectday" id="kill-list-page">
            <Helmet title="Select a day" />
            <div className="header">
                <div className="container">
                    <div className="header__inner">
                        <Row type="flex" justify="space-between" align="middle">
                            <Col span={14}>
                                <div className="flex-center-stretch">
                                    <Menu />
                                    {type === 'killed' ? 'Full kill list' : 'Full not killed list'}
                                </div>
                            </Col>
                            <Col span={10}>
                                <AllowanceWidget />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            { isFetching
                ? (
                    <div className="container">
                        <Spin spinning={isFetching}>
                            <div style={{ marginTop: '140px', opacity: 0.5 }} />
                        </Spin>
                    </div>
                )
                : (
                    <Tabs defaultActiveKey={activeTab} className="ant-tabs--styled" onChange={(key) => this.changeActiveTab(key)}>
                        <TabPane tab="Killed" key="killed">
                            { _.isEmpty(killedDays)
                                ? (
                                    <div style={{ paddingTop: 20 }}>
                                        <Empty description={<span>No production days found</span>} />
                                    </div>
                                )
                                : (
                                    <div>
                                        <ActiveDaySelector days={killedDays} onChange={this.changeActiveKilledDay.bind(this)} value={selectedKilledDay && selectedKilledDay.id ? selectedKilledDay.id : 0} />
                                        {_.isEmpty(selectedKilledDay)
                                            ? (
                                                <div className="container">
                                                    <div style={{ paddingTop: 20 }}>
                                                        <Empty
                                                            description={<span>No images found</span>} />
                                                    </div>
                                                </div>
                                            )
                                            : <ImagesList key={selectedKilledDay.id} uniqueId={`images-list-${selectedKilledDay.id}`} dayImages={selectedKilledDay.images} title={selectedKilledDay.name} user={user} onChange={(data) => this.saveCurrentRowPosition(data)} imagesListPosition={imagesListPosition} />}
                                    </div>
                                )}
                        </TabPane>
                        {currentUser.user_type !== ROLE_ACTOR
                            ? (
                                <TabPane tab="Unkilled" key="unkilled">
                                    { _.isEmpty(unkilledDays)
                                        ? (
                                            <div style={{ paddingTop: 20 }}>
                                                <Empty description={<span>No production days found</span>} />
                                            </div>
                                        )
                                        : (
                                            <div>
                                                <ActiveDaySelector days={unkilledDays} onChange={this.changeActiveUnkilledDay.bind(this)} value={selectedUnkilledDay && selectedUnkilledDay.id ? selectedUnkilledDay.id : 0} />
                                                {_.isEmpty(selectedUnkilledDay)
                                                    ? (
                                                        <div className="container">
                                                            <div style={{ paddingTop: 20 }}>
                                                                <Empty
                                                                    description={<span>No images found</span>} />
                                                            </div>
                                                        </div>
                                                    )
                                                    : <ImagesList key={selectedUnkilledDay.id} uniqueId={`images-list-${selectedUnkilledDay.id}`} dayImages={selectedUnkilledDay.images} title={selectedUnkilledDay.name} user={user} blockHeight={450} onChange={(data) => this.saveCurrentRowPosition(data)} imagesListPosition={imagesListPosition} />}
                                            </div>
                                        )}
                                </TabPane>
                            )
                            : null }
                    </Tabs>
                )}
        </div>
    );
}
