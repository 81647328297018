const {
    REACT_APP_ENV,
    REACT_APP_API_URL,
    REACT_APP_BUGSNAG_KEY,
    REACT_APP_CAPTION_MODE,
    REACT_APP_API_BASE_URL,

    REACT_APP_S3_USERNAME,
    REACT_APP_S3_ACCESS_KEY,
    REACT_APP_S3_SECRET,
    REACT_APP_S3_BUCKET,
    REACT_APP_S3_REGION,
} = process.env;

// APP CONSTATNS

const app = {
    APP_ENV: REACT_APP_ENV,
    APP_DEBUG: REACT_APP_ENV === 'local',
    MOCK: false,
    CAPTION_MODE: REACT_APP_CAPTION_MODE,

    APP_NAME: 'Image Approval',
    BASE_URL: REACT_APP_API_BASE_URL,
    BASE_URL_DEBUG: REACT_APP_API_BASE_URL,
    API_URL: REACT_APP_API_URL,

    STACKABLE_KEY: 'bBAtfmcHmgAz',
    GALLERY_CONTAINER: '2n6Lbqw8oAzpQB5Zt',

    AUTH_KEY: 'access-token',
    API_LOGIN_KEY: 'api-login-access',
    REGISTER_AUTH_KEY: 'register-api-key',
    LOCALSTORAGE_EXPIRES_KEY: 'expire-auth',
    LOCALSTORAGE_EXPIRES: '30', // minutes
    APP_VERSION: 'app_version',

    S3_USERNAME: REACT_APP_S3_USERNAME,
    S3_ACCESS_KEY: REACT_APP_S3_ACCESS_KEY,
    S3_SECRET: REACT_APP_S3_SECRET,
    S3_BUCKET: REACT_APP_S3_BUCKET,
    S3_REGION: REACT_APP_S3_REGION,

    BUGSNAG_KEY: REACT_APP_BUGSNAG_KEY,
};

export default app;
