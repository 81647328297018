import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DOM from './menu';
import * as globalActions from '../../core/global/globalActions';
import * as productionActions from '../../core/production/productionActions';
import * as userActions from '../../core/user/userActions';

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
        };
        this.view = DOM;
    }

    handleLogout = (e) => {
        e.preventDefault();
        const { actions } = this.props;
        actions.logoutRequest();
    }

    handleMenuBackdropClick = () => {
        this.setState({ menuOpen: !this.state.menuOpen });
    };

    handleMenuClick = () => {
        this.setState({ menuOpen: !this.state.menuOpen });
    };

    handleLinkClick = () => {
        this.setState({ menuOpen: false });
    };

    render() {
        return this.view();
    }
}

Menu.propTypes = {
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedProduction: state.production.selectedProduction,
        userProfile: state.user.user,
        isFetching: state.production.isFetching,
        productionUserStats: state.production.productionUserStats,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...productionActions,
                ...userActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Menu);
