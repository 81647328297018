import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'underscore';
import * as globalActions from '../../core/global/globalActions';
import * as productionActions from '../../core/production/productionActions';
import DOM from './reviewImage';
import { ROLE_ACTOR, ROLE_AGENT } from '../../core/utils/value';

class ReviewImage extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            infoModalVisible: false,
            buttonsArray: [],
        };
    }

    componentDidMount() {
        const { actions, params, selectedProductionDay, user } = this.props;

        // actions.getUserSingleProductionRequest({
        //     slug: params.production
        // });
        // actions.getUserSingleProductionDayRequest({
        //     productionSlug: params.production,
        //     daySlug: params.day
        // });
        actions.getUserSingleImageRequest({
            productionSlug: params.production,
            daySlug: params.day,
            imageName: params.image,
            callFrom: 'mount',
            user,
        });
    }

    componentDidUpdate(prevProps) {
        const { actions, params, user } = this.props;
        const { params: prevParams } = prevProps;

        const changedProduction = params.production !== prevParams.production;
        const changedDay = params.day !== prevParams.day;
        const changedStatus = params.status !== prevParams.status;

        if (changedProduction) {
            actions.getUserSingleProductionRequest({
                slug: params.production
            });
        }

        if (changedProduction || changedDay) {
            actions.getUserSingleProductionDayRequest({
                productionSlug: params.production,
                daySlug: params.day
            });
        }

        if (changedProduction || changedDay || params.image !== prevParams.image) {
            actions.getUserSingleImageRequest({
                productionSlug: params.production,
                daySlug: params.day,
                imageName: params.image,
                callFrom: 'props',
                user,
            });
        }
    }

    componentWillUnmount() {
        const { actions } = this.props;
        actions.resetSelectedProductionDayImageRequest();
    }

    checkArrayDifference = (array1, array2) => {
        if (array1 === undefined || array2 === undefined) {
            return array1 !== array2;
        }

        if (array1.length !== array2.length) {
            return true;
        }

        // eslint-disable-next-line
        for (let i = 0; i < array1.length; i++) {
            const value1 = array1[i];
            const value2 = array2[i];

            if (typeof value1 !== typeof value2) {
                return true;
            }

            if (Array.isArray(value1) && Array.isArray(value2)) {
                if (this.checkArrayDifference(value1, value2)) {
                    return true;
                }
            } else if (typeof value1 === 'object' && typeof value2 === 'object') {
                if (JSON.stringify(value1) !== JSON.stringify(value2)) {
                    return true;
                }
            } else if (value1 !== value2) {
                return true;
            }
        }

        return false;
    }

    filterSelectedImages = (params, user, image) => {
        const { selectedProduction } = this.props;
        const { currentUser } = selectedProduction;
        if (params && params.status) {
            if (params.status === 'viewed' && image.views.length > 0) {
                let found = true;
                _.each(image.views, (viewedView) => {
                    if (parseInt(viewedView.killed, 10) === 1) {
                        if ((currentUser.user_type === ROLE_ACTOR || currentUser.user_type === ROLE_AGENT) && parseInt(user.UID, 10) === parseInt(viewedView.user_id, 10)) {
                            found = false;
                        }
                    }
                });
                return found;
            } if (params.status === 'inactive') {
                const userInactiveView = _.filter(image.views, (inactiveView) => parseInt(inactiveView.user_id, 10) === parseInt(user.UID, 10));
                if (userInactiveView.length > 0) {
                    return false;
                }
                return true;
            } if ((params.status === 'killed' || params.status === 'kill-list') && image.views.length > 0) {
                let found = false;
                _.each(image.views, (view) => {
                    if (parseInt(view.killed, 10) === 1 && found === false) {
                        if ((currentUser.user_type === ROLE_ACTOR && parseInt(user.UID, 10) == parseInt(view.user_id, 10)) || currentUser.user_type !== ROLE_ACTOR) {
                            found = true;
                        }
                    }
                });
                return found;
            } if (params.status === 'not-killed') {
                let found = true;
                _.each(image.views, (view) => {
                    if (parseInt(view.killed, 10) === 1 && found === true) {
                        if ((currentUser.user_type === ROLE_ACTOR && parseInt(user.UID, 10) == parseInt(view.user_id, 10)) || currentUser.user_type !== ROLE_ACTOR) {
                            found = false;
                        }
                    }
                });
                return found;
            } if (params.status === 'unkilled-selects' && image.views.length > 0) {
                let found = true;
                _.each(image.views, (view) => {
                    if (parseInt(view.killed, 10) === 1 && found === true) {
                        if ((currentUser.user_type === ROLE_ACTOR && parseInt(user.UID, 10) == parseInt(view.user_id, 10)) || currentUser.user_type !== ROLE_ACTOR) {
                            found = false;
                        }
                    }
                });

                if (image.is_selected == 1 && found) {
                    return true;
                }
                return false;
            } if (params.status === 'selects') {
                return image.is_selected == 1;
            } if (params.status === 'all') {
                return true;
            }
        }
        return false;
    }

    grabPreviousNextImagesArray = (customSelectedProductionDay) => {
        if (!_.isEmpty(this.state.buttonsArray)) {
            return true;
        }

        const {
            selectedProductionDayImage, params, user, selectedActor
        } = this.props;
        const dayImages = [];
        if (params.status !== 'kill-list' && params.status !== 'selects' && params.status !== 'not-killed' && params.status !== 'unkilled-selects') {
            const selectedDay = customSelectedProductionDay && customSelectedProductionDay.productionDays && customSelectedProductionDay.productionDays.filter((day) => day.slug === params?.day)[0];
            let count = 0;
            _.each(selectedDay.images, (image) => {
                let imageOk = true;
                if (!_.isNull(selectedActor)) {
                    imageOk = false;
                    if (_.isNull(selectedActor.id) && image.tags.length <= 0) {
                        imageOk = true;
                    }
                    _.each(image.tags, (tag) => {
                        _.each(tag, (tagUserId, tagUserName) => {
                            if (_.isNull(selectedActor.id)) {
                                if (tagUserId === selectedActor.id) {
                                    imageOk = true;
                                }
                            } else if (parseInt(tagUserId, 10) === parseInt(selectedActor.id, 10)) {
                                imageOk = true;
                            }
                        });
                    });
                }
                if (imageOk) {
                    const temp = image;
                    temp.production_slug = customSelectedProductionDay.slug;
                    temp.production_day_slug = selectedDay.slug;
                    dayImages[count] = temp;
                    count += 1;
                }
            });
        } else {
            let count = 0;
            _.each(customSelectedProductionDay.productionDays, (day) => {
                let proceed = true;
                if (params.status == 'not-killed' && !day.markedDoneActor) {
                    proceed = false;
                }

                if (proceed) {
                    _.each(day.images, (image) => {
                        const temp = image;
                        temp.production_slug = customSelectedProductionDay.slug;
                        temp.production_day_slug = day.slug;
                        dayImages[count] = temp;
                        count += 1;
                    });
                }
            });
        }
        const imageDetailsArray = [];

        // Filter according to user and params
        let selectedImages = dayImages.filter((image) => this.filterSelectedImages(params, user, image));
        selectedImages = selectedImages.filter((image) => image.production_day_slug === params.day); 
        
        _.each(selectedImages, (image, imageIndex) => {
            let prevImageDetails = {
                previousImageId: null,
                previousImageName: null,
                previousImageUrl: null,
                previousImagePublicUrl: null
            };
            let nextImageDetails = {
                nextImageId: null,
                nextImageName: null,
                nextImageUrl: null,
                nextImagePublicUrl: null
            };

            const customProductionSlug = image.production_slug || customSelectedProductionDay.slug;
            const customProductionDaySlug = image.production_day_slug || customSelectedProductionDay.productionDays[0].slug;

            if (imageIndex > 0) {
                prevImageDetails = {
                    previousImageId: selectedImages[imageIndex - 1].id,
                    previousImageName: selectedImages[imageIndex - 1].name,
                    previousImageUrl: params && params.status
                        ? `/production/${customProductionSlug}/${customProductionDaySlug}/${selectedImages[imageIndex - 1].name}/${params.status}`
                        : `/production/${customProductionSlug}/${customProductionDaySlug}/${selectedImages[imageIndex - 1].name}`,
                    previousImagePublicUrl: selectedImages[imageIndex - 1].publicUrl
                };
            }

            if (imageIndex < (selectedImages.length - 1)) {
                nextImageDetails = {
                    nextImageId: selectedImages[imageIndex + 1].id,
                    nextImageName: selectedImages[imageIndex + 1].name,
                    nextImageUrl: params && params.status
                        ? `/production/${customProductionSlug}/${customProductionDaySlug}/${selectedImages[imageIndex + 1].name}/${params.status}`
                        : `/production/${customProductionSlug}/${customProductionDaySlug}/${selectedImages[imageIndex + 1].name}`,
                    nextImagePublicUrl: selectedImages[imageIndex + 1].publicUrl
                };
            }
            imageDetailsArray[image.id] = { ...prevImageDetails, ...nextImageDetails };
        });

        this.setState({ buttonsArray: imageDetailsArray });

        return imageDetailsArray;
    }

    render() {
        return this.view();
    }
}

ReviewImage.defaultProps = {
    selectedProductionDayImage: null
};

ReviewImage.propTypes = {
    actions: PropTypes.object.isRequired,
    selectedProductionDayImage: PropTypes.object,
    params: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedProduction: state.production.selectedProduction,
        selectedProductionDay: state.production.selectedProductionDay,
        selectedProductionDayImage: state.production.selectedProductionDayImage,
        isFetching: state.production.isFetching,
        selectedActor: state.production.selectedActor,
        user: state.user.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...productionActions
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReviewImage);
