import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { purgeStoredState } from 'redux-persist';
import alertify from 'alertify.js';
import lockr from 'lockr';
import { browserHistory } from 'react-router';
import localforage from 'localforage';
// import gallery from './gallery/galleryReducer';
import global from './global/globalReducer';
import user from './user/userReducer';
import production from './production/productionReducer';
import s3Links from './s3Links/s3LinksReducer';

/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */

const reducers = combineReducers({
    // gallery,
    global,
    user,
    production,
    s3Links,
    form: formReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT_SUCCESS') {
        purgeStoredState({ storage: localforage });
        lockr.flush();
        localforage.clear();
        browserHistory.push('/');
    }
    if ((action.type).indexOf('_FAILURE') !== -1) {
        if (action.payload === 'Your request was made with invalid credentials.') {
            lockr.flush();
            localforage.clear();
            alertify.error('Session expired, please login again.');
            browserHistory.push('/');
        }
    }

    return reducers(state, action);
};

export default rootReducer;
