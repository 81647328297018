import React from 'react';
import PropTypes from 'prop-types';
import spriteSrc from '../assets/images/sprite.svg';

function Icon(props) {
    const { name, className } = props;
    return (
        <svg className={`icon icon-${name} ${className}`}>
            <use xlinkHref={`${spriteSrc}#icon-${name}`} />
        </svg>
    );
}

Icon.defaultProps = {
    name: '',
    className: '',
};

Icon.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
};
export default Icon;
