/* eslint-disable react/no-this-in-sfc */

import React from 'react';
import { Link } from 'react-router';
import _ from 'underscore';
import './header.css';

export default function () {
    const { handleLogout, title } = this.props;
    return (
        <div className="header">
            <div className="container">
                <div className="header__inner">
                    {title}
                    {!_.isNull(handleLogout)
                        ? <Link onClick={handleLogout} style={{ float: 'right' }}>Logout</Link>
                        : null}
                </div>
            </div>
        </div>
    );
}
