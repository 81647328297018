import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'underscore';
import DOM from './dayimages';
import * as globalActions from '../../core/global/globalActions';
import * as productionActions from '../../core/production/productionActions';
import { ROLE_ACTOR } from '../../core/utils/value';

class Dayimages extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            images: [],
            scrollYElement: localStorage.getItem('dayImageScrollId') || null,
            scrollYPosition: 0,
        };
        this.handleScroll = this.handleScroll.bind(this);
        // window.addEventListener('scroll', () => { this.setState({ scrollYPosition: window.scrollY }); }, { passive: true });
    }

    componentWillMount() {
        const { images, selectedActor, user, selectedProduction } = this.props;
        const { currentUser } = selectedProduction;

        const filteredImages = _.isNull(selectedActor) || currentUser.user_type === ROLE_ACTOR
            ? images
            : _.filter(images, (image) => {
                let imageOk = false;
                if (_.isNull(selectedActor)) {
                    return true;
                }
                if (selectedActor && _.isNull(selectedActor.id) && image.tags.length <= 0) {
                    return true;
                }
                _.each(image.tags, (tag) => {
                    _.each(tag, (tagUserId) => {
                        if (_.isNull(selectedActor.id)) {
                            if (tagUserId === selectedActor.id) {
                                imageOk = true;
                            }
                        } else if (parseInt(tagUserId, 10) === parseInt(selectedActor.id, 10)) {
                            imageOk = true;
                        }
                    });
                });
                return imageOk;
            });
        if (!_.isNull(filteredImages)) {
            this.setState({
                images: filteredImages,
            });
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, { passive: true });
        const scrollYPosition = localStorage.getItem('scrollYPosition');
        if (!_.isUndefined(scrollYPosition)) {
            window.scrollTo(0, localStorage.getItem('scrollYPosition'));
        }
    }

    componentWillReceiveProps(nextProps) {
        const { images, selectedActor, user, selectedProduction } = nextProps;
        const { currentUser } = selectedProduction;
        const filteredImages = _.isNull(selectedActor) || currentUser.user_type === ROLE_ACTOR
            ? images
            : _.filter(images, (image) => {
                let imageOk = false;
                if (_.isNull(selectedActor)) {
                    return true;
                }
                if (selectedActor && _.isNull(selectedActor.id) && image.tags.length <= 0) {
                    return true;
                }
                _.each(image.tags, (tag) => {
                    _.each(tag, (tagUserId) => {
                        if (_.isNull(selectedActor.id)) {
                            if (tagUserId === selectedActor.id) {
                                imageOk = true;
                            }
                        } else if (parseInt(tagUserId, 10) === parseInt(selectedActor.id, 10)) {
                            imageOk = true;
                        }
                    });
                });
                return imageOk;
            });
        if (!_.isNull(filteredImages)) {
            this.setState({
                images: filteredImages,
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        this.setState({ scrollYPosition: window.scrollY });
    }

    selectActor = (value) => {
        const { actions } = this.props;
        if (!_.isUndefined(value) && !_.isNull(value)) {
            const temp = value.split('-');
            actions.setSelectedActorRequest({ id: (temp[0] === 'null' ? null : temp[0]), name: temp[1] });
        } else {
            actions.setSelectedActorRequest(null);
        }
    }

    render() {
        return this.view();
    }
}

Dayimages.defaultProps = {
    markDoneButton: null,
    selectedActor: null,
    status: 'all',
};

Dayimages.propTypes = {
    images: PropTypes.array.isRequired,
    markDoneButton: PropTypes.object,
    status: PropTypes.string,
    actions: PropTypes.object.isRequired,
    selectedActor: PropTypes.object,
    user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        user: state.user.user,
        selectedActor: state.production.selectedActor,
        selectedProduction: state.production.selectedProduction,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...productionActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Dayimages);
