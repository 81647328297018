// import gallerySaga from './gallery/gallerySaga';
import userSaga from './user/userSaga';
import productionSaga from './production/productionSaga';
import s3LinksSaga from './s3Links/s3LinksSaga';

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield [
        // gallerySaga(),
        userSaga(),
        productionSaga(),
        s3LinksSaga(),
    ];
}
