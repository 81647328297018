import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router';
import _ from 'underscore';
import Icon from '../../elements/Icon';

import './navbuttons.css';

export default function () {
    const { buttons } = this.props;
    return (
        <div className="navbuttons">
            <Row>
                <Col span={12}>
                    {!_.isNull(buttons.previousImageUrl)
                        ? (
                            <Link ref="previousImageButton" to={buttons.previousImageUrl} className="btn-empty">
                                <Icon name="arrowback" />
                                <span>Back</span>
                            </Link>
                        )
                        : (
                            <button ref="previousImageButton" type="button" className="btn-empty disabled">
                                <Icon name="arrowback" />
                                <span>Back</span>
                            </button>
                        )}
                </Col>
                <Col span={12}>
                    {!_.isNull(buttons.nextImageUrl)
                        ? (
                            <Link ref="nextImageButton" to={buttons.nextImageUrl} className="btn-empty">
                                <span>Next</span>
                                <Icon name="arrownext-bt" />
                            </Link>
                        )
                        : (
                            <button ref="nextImageButton" type="button" className="btn-empty disabled">
                                <span>Next</span>
                                <Icon name="arrownext-bt" />
                            </button>
                        )}

                </Col>
            </Row>
        </div>
    );
}
