import { Component } from 'react';

import DOM from './killStatistics';

class KillStatistics extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    render() {
        return this.view();
    }
}

export default KillStatistics;
