/* global document: true */
import React from 'react';
import _ from 'underscore';
import { browserHistory } from 'react-router';
import DOM from './navbuttons';

export default class Navbuttons extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        document.addEventListener('keydown', this.KeyControlledNavigation);
    }

    KeyControlledNavigation = (e) => {
        const key = e.which || e.keyCode;
        if (key === 37) {
            // Show previous image
            const previousLink = this.refs.previousImageButton && this.refs.previousImageButton.props && this.refs.previousImageButton.props.to ? this.refs.previousImageButton.props.to : null;
            if (!_.isUndefined(previousLink) && !_.isNull(previousLink)) {
                browserHistory.push(previousLink);
            }
        }
        if (key === 39) {
            // Show next image
            const nextLink = this.refs.nextImageButton && this.refs.nextImageButton.props && this.refs.nextImageButton.props.to ? this.refs.nextImageButton.props.to : null;
            if (!_.isUndefined(nextLink) && !_.isNull(nextLink)) {
                browserHistory.push(nextLink);
            }
        }
    }

    render() {
        return this.view();
    }
}
