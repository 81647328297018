import React from 'react';
import { Link } from 'react-router';
import { Row, Col, Empty } from 'antd';
import _ from 'underscore';
import Icon from '../../elements/Icon';
import './select-day-list.css';
import { ROLE_ACTOR, ROLE_AGENT } from '../../core/utils/value';

export default function () {
    const { days, user, selectedProduction, isFetching } = this.props;
    let currentUser = {};
    if (!isFetching && !_.isEmpty(days)) {
        currentUser = selectedProduction.currentUser;
    }
    return (
        <div className="select-days">
            <div className="container">
                {days.length > 0
                    ? (
                        <span>
                            {_.map(days, (day) => {
                                const link = `/production/${day.productionSlug}/${day.daySlug}`;
                                return (
                                    <div className={`select-day ${day.checked}`} key={day.id}>
                                        <Link to={link}>
                                            <Row type="flex" align="middle">
                                                <Col span={22}>
                                                    <h3 className="select-day__name">
                                                        <Icon name="checked" />
                                                        {' '}
                                                        <span>{day.name}</span>
                                                    </h3>
                                                    <p>
                                                        Viewed:
                                                        {day.viewedStats}
                                                    </p>
                                                    {currentUser.user_type === ROLE_AGENT
                                                        ? null
                                                        : (
                                                            <p>
                                                                Killed:
                                                                {day.killed}
                                                            </p>
                                                        )}
                                                </Col>
                                                <Col span={2}>
                                                    <Icon name="arrownext" />
                                                </Col>
                                            </Row>
                                            <div className="select-day__footer">
                                                {day.doneBy && currentUser.user_type !== ROLE_ACTOR ? (
                                                    <p>
                                                        Marked as done by:
                                                        {' '}
                                                        <span className="c-black">{day.doneBy}</span>
                                                    </p>
                                                ) : null}
                                                {day.waitingFor && currentUser.user_type !== ROLE_ACTOR ? (
                                                    <p>
                                                        Waiting for:
                                                        {' '}
                                                        <span className="c-black">{day.waitingFor}</span>
                                                    </p>
                                                ) : null}
                                                {_.isEmpty(day.waitingFor) && _.isEmpty(day.doneBy)
                                                    ? (
                                                        <p>
                                                            No actors with approval rights in this day's images
                                                        </p>
                                                    )
                                                    : null}
                                            </div>
                                        </Link>
                                    </div>
                                );
                            })}
                        </span>
                    )
                    : (
                        <Empty
                            description={<span>Cannot find any production days with images for you to review</span>} />
                    ) }
            </div>
        </div>
    );
}
