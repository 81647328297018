import { Record } from 'immutable';

const {
    VERIFY_USER_REQUEST,
    VERIFY_USER_SUCCESS,
    VERIFY_USER_FAILURE,

    CONFIRM_S3_UPLOAD_REQUEST,
    CONFIRM_S3_UPLOAD_SUCCESS,
    CONFIRM_S3_UPLOAD_FAILURE,
} = require('./s3LinksActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    linkData: [],
});

const initialState = new InitialState();

/**
 * ## userReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function userReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case CONFIRM_S3_UPLOAD_REQUEST:
    case VERIFY_USER_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case VERIFY_USER_SUCCESS:
        return state.set('isFetching', false)
            .set('error', null)
            .set('linkData', payload);

    case CONFIRM_S3_UPLOAD_SUCCESS:
        return state.set('error', null).set('isFetching', false).set('linkData', []);

    case CONFIRM_S3_UPLOAD_FAILURE:
    case VERIFY_USER_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;
    }
}
