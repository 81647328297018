/* eslint-disable no-case-declarations */
import { Record } from 'immutable';
import _ from 'underscore';
import alertify from 'alertify.js';

const {
    GET_USER_PRODUCTION_REQUEST,
    GET_USER_PRODUCTION_SUCCESS,
    GET_USER_PRODUCTION_FAILURE,

    GET_USER_SINGLE_PRODUCTION_REQUEST,
    GET_USER_SINGLE_PRODUCTION_SUCCESS,
    GET_USER_SINGLE_PRODUCTION_FAILURE,

    GET_USER_SINGLE_PRODUCTION_DAY_REQUEST,
    GET_USER_SINGLE_PRODUCTION_DAY_SUCCESS,
    GET_USER_SINGLE_PRODUCTION_DAY_FAILURE,

    GET_USER_SINGLE_IMAGE_REQUEST,
    GET_USER_SINGLE_IMAGE_SUCCESS,
    GET_USER_SINGLE_IMAGE_FAILURE,

    USER_KILL_IMAGE_REQUEST,
    USER_KILL_IMAGE_SUCCESS,
    USER_KILL_IMAGE_FAILURE,

    USER_MARK_DAY_DONE_REQUEST,
    USER_MARK_DAY_DONE_SUCCESS,
    USER_MARK_DAY_DONE_FAILURE,

    GET_ALL_RESELLERS_REQUEST,
    GET_ALL_RESELLERS_SUCCESS,
    GET_ALL_RESELLERS_FAILURE,

    SEND_ACCESS_REQUEST_REQUEST,
    SEND_ACCESS_REQUEST_SUCCESS,
    SEND_ACCESS_REQUEST_FAILURE,

    GET_ALL_ACTORS_REQUEST,
    GET_ALL_ACTORS_SUCCESS,
    GET_ALL_ACTORS_FAILURE,

    ADD_TAG_USER_REQUEST,
    ADD_TAG_USER_SUCCESS,
    ADD_TAG_USER_FAILURE,

    REMOVE_TAG_USER_REQUEST,
    REMOVE_TAG_USER_SUCCESS,
    REMOVE_TAG_USER_FAILURE,

    GET_DOWNLOAD_QUEUE_REQUEST,
    GET_DOWNLOAD_QUEUE_SUCCESS,
    GET_DOWNLOAD_QUEUE_FAILURE,

    GET_DOWNLOAD_FILE_REQUEST,
    GET_DOWNLOAD_FILE_SUCCESS,
    GET_DOWNLOAD_FILE_FAILURE,

    GET_DOWNLOAD_IMAGE_REQUEST,
    GET_DOWNLOAD_IMAGE_SUCCESS,
    GET_DOWNLOAD_IMAGE_FAILURE,

    SET_SELECTED_ACTOR_REQUEST,
    SET_SELECTED_ACTOR_SUCCESS,

    SET_IMAGE_SELECTED_FLAG_REQUEST,
    SET_IMAGE_SELECTED_FLAG_SUCCESS,
    SET_IMAGE_SELECTED_FLAG_FAILURE,

    RESET_SELECTED_ACTOR_REQUEST,

    UPDATE_IMAGE_CAPTION_REQUEST,
    UPDATE_IMAGE_CAPTION_SUCCESS,
    UPDATE_IMAGE_CAPTION_FAILURE,

    PRELOAD_IMAGES_REQUEST,
    PRELOAD_IMAGES_SUCCESS,
    PRELOAD_IMAGES_FAILURE,
    RESET_PRELOAD_IMAGES_REQUEST,
    RESET_SELECTED_PRODUCTION_DAY_IMAGE_REQUEST,
    SYNC_LOCAL_IMAGES_REQUEST,

    GET_PRODUCTION_USER_STATS_REQUEST,
    GET_PRODUCTION_USER_STATS_SUCCESS,
    GET_PRODUCTION_USER_STATS_FAILURE,

} = require('./productionActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    isSingleImageFetching: false,
    productions: [],
    selectedProduction: null,
    selectedProductionTemp: {},
    selectedProductionDay: null,
    selectedProductionDayImage: null,
    resellersList: [],
    resellerListFetching: false,
    allActors: [],
    isActorsFetching: false,
    downloadQueue: [],
    selectedActor: null,
    preloadImages: {},
    productionUserStats: {},
    userStatsIsFetching: false,
});

const initialState = new InitialState();

/**
 * ## productionReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function productionReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case GET_USER_PRODUCTION_REQUEST:
    case GET_USER_SINGLE_PRODUCTION_REQUEST:
    case GET_USER_SINGLE_PRODUCTION_DAY_REQUEST:
    case USER_MARK_DAY_DONE_REQUEST:
    case SEND_ACCESS_REQUEST_REQUEST:
    case ADD_TAG_USER_REQUEST:
    case REMOVE_TAG_USER_REQUEST:
    case GET_DOWNLOAD_QUEUE_REQUEST:
    case GET_DOWNLOAD_FILE_REQUEST:
    case GET_DOWNLOAD_IMAGE_REQUEST:
    case SET_SELECTED_ACTOR_REQUEST:
    case SET_IMAGE_SELECTED_FLAG_REQUEST:
    case UPDATE_IMAGE_CAPTION_REQUEST:
    case PRELOAD_IMAGES_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_PRODUCTION_USER_STATS_REQUEST:
        return state.set('userStatsIsFetching', true)
            .set('error', null);

    case GET_USER_SINGLE_IMAGE_REQUEST:
    case USER_KILL_IMAGE_REQUEST:
        return state.set('isFetching', true)
            .set('isSingleImageFetching', true)
            .set('error', null);

    case RESET_SELECTED_ACTOR_REQUEST:
        return state.set('selectedActor', null);

    case RESET_SELECTED_PRODUCTION_DAY_IMAGE_REQUEST:
        return state.set('selectedProductionDayImage', null);

    case GET_ALL_ACTORS_REQUEST:
        return state.set('isFetching', false)
            .set('isActorsFetching', true)
            .set('error', null);

    case SET_SELECTED_ACTOR_SUCCESS:
        return state.set('isFetching', false)
            .set('selectedActor', payload);

    case GET_PRODUCTION_USER_STATS_SUCCESS:
        return state.set('userStatsIsFetching', false)
            .set('productionUserStats', payload.data);
    case GET_ALL_ACTORS_SUCCESS:
        return state.set('isFetching', false)
            .set('isActorsFetching', false)
            .set('allActors', payload.data);
    case GET_ALL_RESELLERS_REQUEST:
        return state.set('resellerListFetching', true)
            .set('resellersList', [])
            .set('error', null);
    case GET_USER_PRODUCTION_SUCCESS:
        return state.set('isFetching', false)
            .set('productions', payload.data);
    case GET_USER_SINGLE_PRODUCTION_SUCCESS:
        let productionData = payload.data;
        if (_.isEmpty(productionData)) {
            productionData = {};
            productionData.currentUser = {};
            productionData.currentUser.groupKillLimit = 0;
            productionData.currentUser.groupKills = 0;
            productionData.currentUser.soloKillLimit = 0;
            productionData.currentUser.soloKills = 0;
            productionData.totalProductionImages = 0;
        }
        const userStats = {};
        userStats.currentUser = productionData.currentUser;
        userStats.totalProductionImages = productionData.totalProductionImages;
        userStats.usersStat = productionData.usersStat;
        return state.set('isFetching', false)
            .set('selectedProduction', productionData)
            .set('productionUserStats', userStats);
    case GET_USER_SINGLE_PRODUCTION_DAY_SUCCESS:
    case USER_MARK_DAY_DONE_SUCCESS:
        return state.set('isFetching', false)
            .set('selectedProduction', payload.data);
    case SET_IMAGE_SELECTED_FLAG_SUCCESS:
    case UPDATE_IMAGE_CAPTION_SUCCESS:
        return state.set('isFetching', false)
            .set('selectedProductionDayImage', payload.data);
    case GET_USER_SINGLE_IMAGE_SUCCESS: {
        const { selectedProduction } = state;
        const { user } = payload;
        const { data: imageData } = payload;

        let selectedProductionTemp;

        if (_.isEmpty(state.selectedProductionTemp) || (state?.selectedProduction?.id !== state?.selectedProductionTemp?.id)) {
            // Deep clone selectedProduction including nested objects and arrays
            selectedProductionTemp = {
                ...selectedProduction,
                productionDays: selectedProduction.productionDays.map(productionDay => ({
                    ...productionDay,
                    images: productionDay.images.map(image => ({
                        ...image,
                        views: [...image.views]
                    }))
                }))
            };
        } else {
            selectedProductionTemp = {
                ...state.selectedProductionTemp,
                productionDays: state.selectedProductionTemp.productionDays.map(productionDay => ({
                    ...productionDay,
                    images: productionDay.images.map(image => ({
                        ...image,
                        views: [...image.views]
                    }))
                }))
            };
        }

        const productionIndex = selectedProductionTemp.productionDays.findIndex(
            productionDay => productionDay.id === imageData.production_day.id
        );

        if (productionIndex >= 0) {
            // Find the image in the production day
            const imageIndex = selectedProductionTemp.productionDays[productionIndex].images.findIndex(
                image => image.id === imageData.id
            );

            if (imageIndex >= 0) {
                const image = selectedProductionTemp.productionDays[productionIndex].images[imageIndex];
                // Check if the user already exists in the views array
                const userExists = image.views.some(view => view.user_id === user?.UID);

                // Mark the image as viewed
                if (!userExists) {
                    image.views.push({
                        user_id: user?.UID,
                        full_name: user?.fullName,
                        viewed_on: new Date().toISOString() // Current timestamp
                    });
                }
            }
        }

        return state.set('isFetching', false)
            .set('isSingleImageFetching', false)
            .set('selectedProductionDayImage', payload.data)
            .set('selectedProductionTemp', { ...selectedProductionTemp });
    }

    case SYNC_LOCAL_IMAGES_REQUEST: {
        const { selectedProductionTemp, selectedProduction } = state;

        // Ensure selectedProductionTemp is not empty or null
        if (!_.isEmpty(selectedProductionTemp)) {
            if (selectedProductionTemp?.id === selectedProduction?.id) {
                return state.set('selectedProduction', selectedProductionTemp);
            }
        }

        // If selectedProductionTemp is empty or null, return the state unchanged
        return state;
    }

    case ADD_TAG_USER_SUCCESS:
    case REMOVE_TAG_USER_SUCCESS:
        return state.set('isFetching', false)
            .set('selectedProduction', payload.data.production)
            .set('selectedProductionDayImage', payload.data.image);
    case GET_ALL_RESELLERS_SUCCESS:
        return state.set('resellerListFetching', false)
            .set('resellersList', payload.data);
    case USER_KILL_IMAGE_SUCCESS:
        const { selectedProduction } = state;
        const { selectedProductionDayImage } = state;

        if (payload.data) {
            const killUserKey = payload.data && payload.data.currentImageKilledUser ? _.findIndex(selectedProductionDayImage.users, { id: parseInt(payload.data.currentImageKilledUser, 10) }) : -1;
            if (payload.data.groupKill === true) {
                selectedProduction.currentUser.groupKills += 1;
                if (selectedProductionDayImage.users && killUserKey !== -1) {
                    selectedProductionDayImage.users[killUserKey].killed = '1';
                    selectedProductionDayImage.status = 'killed';
                }
            } else if (payload.data.upperGroupKill === true) {
                selectedProduction.currentUser.upperGroupKills += 1;
                if (selectedProductionDayImage.users && killUserKey !== -1) {
                    selectedProductionDayImage.users[killUserKey].killed = '1';
                    selectedProductionDayImage.status = 'killed';
                }
            } else if (payload.data.soloKill === true) {
                selectedProduction.currentUser.soloKills += 1;

                if (selectedProductionDayImage.users && killUserKey !== -1) {
                    selectedProductionDayImage.users[killUserKey].killed = '1';
                    selectedProductionDayImage.status = 'killed';
                }
            } else if (payload.data.soloUnKill === true) {
                selectedProduction.currentUser.soloKills -= 1;

                if (selectedProductionDayImage.users && killUserKey !== -1) {
                    selectedProductionDayImage.users[killUserKey].killed = '0';
                    selectedProductionDayImage.status = 'active';
                }
            } else if (payload.data.groupUnKill === true) {
                selectedProduction.currentUser.groupKills -= 1;

                if (selectedProductionDayImage.users && killUserKey !== -1) {
                    selectedProductionDayImage.users[killUserKey].killed = '0';
                    selectedProductionDayImage.status = 'active';
                }
            } else if (payload.data.upperGroupUnKill === true) {
                selectedProduction.currentUser.upperGroupKills -= 1;

                if (selectedProductionDayImage.users && killUserKey !== -1) {
                    selectedProductionDayImage.users[killUserKey].killed = '0';
                    selectedProductionDayImage.status = 'active';
                }
            } else if (payload.data.isRemoved === false) {
                if (selectedProductionDayImage.users && killUserKey !== -1) {
                    selectedProductionDayImage.status = 'active';
                }
            }

            if (payload.data.isRemoved === true) {
                if (selectedProductionDayImage.users && killUserKey !== -1) {
                    selectedProductionDayImage.status = 'removed';
                }
            }

            if (payload.data.limitReached && payload.data.limitReached === true) {
                alertify.error('You have reached your kill allowance for this category of image. Please review your kill allowances.');
                return state.set('isFetching', false);
            }
        }

        return state.set('isFetching', true)
            .set('isSingleImageFetching', false)
            .set('selectedProduction', selectedProduction)
            .set('selectedProductionDayImage', selectedProductionDayImage);
    case SEND_ACCESS_REQUEST_SUCCESS:
        return state.set('isFetching', false);
    case GET_DOWNLOAD_IMAGE_SUCCESS:
        return state.set('isFetching', false);
    case GET_DOWNLOAD_FILE_SUCCESS:
        let { downloadQueue } = state;
        if (payload.data && payload.data.pdf) {
            downloadQueue = payload.data.pdf;
        }
        return state.set('isFetching', false)
            .set('downloadQueue', downloadQueue);
    case GET_DOWNLOAD_QUEUE_SUCCESS:
        return state.set('isFetching', false)
            .set('downloadQueue', payload.data);
    case GET_USER_PRODUCTION_FAILURE:
    case GET_USER_SINGLE_PRODUCTION_FAILURE:
    case GET_USER_SINGLE_PRODUCTION_DAY_FAILURE:
    case USER_MARK_DAY_DONE_FAILURE:
    case GET_ALL_RESELLERS_FAILURE:
    case SEND_ACCESS_REQUEST_FAILURE:
    case GET_ALL_ACTORS_FAILURE:
    case ADD_TAG_USER_FAILURE:
    case REMOVE_TAG_USER_FAILURE:
    case GET_DOWNLOAD_QUEUE_FAILURE:
    case GET_DOWNLOAD_FILE_FAILURE:
    case GET_DOWNLOAD_IMAGE_FAILURE:
    case SET_IMAGE_SELECTED_FLAG_FAILURE:
    case UPDATE_IMAGE_CAPTION_FAILURE:
    case PRELOAD_IMAGES_FAILURE:
    case GET_PRODUCTION_USER_STATS_FAILURE:
        return state.set('isFetching', false)
            .set('resellerListFetching', false)
            .set('isActorsFetching', false)
            .set('userStatsIsFetching', false)
            .set('error', payload);

    case USER_KILL_IMAGE_FAILURE:
    case GET_USER_SINGLE_IMAGE_FAILURE:
        return state.set('isFetching', false)
            .set('isSingleImageFetching', false)
            .set('resellerListFetching', false)
            .set('isActorsFetching', false)
            .set('error', payload);
    case PRELOAD_IMAGES_SUCCESS:
        const { preloadImages } = state;
        preloadImages[payload.id] = payload.response;
        return state.set('isFetching', false)
            .set('preloadImages', preloadImages);

    case RESET_PRELOAD_IMAGES_REQUEST:
        return state.set('preloadImages', {});
    default:
        return state;
    }
}
