import React from 'react';
import PropTypes from 'prop-types';
import DOM from './cardList';

class CardList extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    render() {
        return this.view(this.props);
    }
}
CardList.defaultProps = {
    cards: [],
};

CardList.propTypes = {
    cards: PropTypes.array,
};
export default CardList;
