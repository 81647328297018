import { call, put, takeEvery } from 'redux-saga/effects';
// import _ from 'underscore';
import { browserHistory } from 'react-router';
import alertify from 'alertify.js';
import { fetchApi, getAuthKey } from '../utils/api';
import { verifyUserFailure, verifyUserSuccess, confirmS3UploadFailure, confirmS3UploadSuccess } from './s3LinksActions';

const {
    VERIFY_USER_REQUEST,
    CONFIRM_S3_UPLOAD_REQUEST,
} = require('./s3LinksActions').constants;

function* verifyUser(action) {
    try {
        const headers = {};
        const authKey = getAuthKey();
        if (authKey) {
            headers.Authorization = `Bearer ${authKey}`;
        }
        const response = yield call(fetchApi, {
            method: 'post',
            url: '/upload-links/verify-user',
            body: action.payload,
        }, headers);

        if (response.data === false) {
            alertify.error('You do not have access to this link');
            // eslint-disable-next-line no-undef
            const currentLocation = window.location.pathname + window.location.search;
            browserHistory.push(`/login?redirect=${encodeURIComponent(currentLocation)}`);
            
            yield put(verifyUserSuccess([]));
        } else {
            yield put(verifyUserSuccess(response.data));
        }
    } catch (e) {
        yield put(verifyUserFailure(e.response ? e.response.data.message : e));
    }
}

function* confirmS3Upload(action) {
    try {
        const res = yield call(fetchApi, {
            method: 'post',
            url: '/upload-links/confirm-upload',
            body: action.payload,
        });

        yield put(confirmS3UploadSuccess());
    } catch (e) {
        yield put(confirmS3UploadFailure(e.response ? e.response.data.message : e));
    }
}

export default function* userSaga() {
    yield* [
        takeEvery(VERIFY_USER_REQUEST, verifyUser),
        takeEvery(CONFIRM_S3_UPLOAD_REQUEST, confirmS3Upload),
    ];
}
