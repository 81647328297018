/* global localStorage */
/* eslint-disable react/no-this-in-sfc */

import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router';
import {
    Row, Col, Spin, Tabs
} from 'antd';
import _, { isNull, isUndefined } from 'underscore';
import Dayimages from '../../components/Dayimages';
import Icon from '../../elements/Icon';
import ButtonIcon from '../../elements/ButtonIcon';
import imagePlaceholder from '../../assets/images/placeholder-image.png';
import Menu from '../../components/Menu';
import AllowanceWidget from '../../components/AllowanceWidget';
import { IMAGE_VIEW_ALL, ROLE_VIEWER, ROLE_ACTOR } from '../../core/utils/value';

const {
    BASE_URL,
    BASE_URL_DEBUG,
    APP_DEBUG,
} = require('../../core/constants').default;

const tabNames = ['viewed', 'unviewed', 'killed', 'all'];

export default function () {
    const { selectedProductionDay, isFetching, user, selectedProduction } = this.props;
    const API_BASE_URL = APP_DEBUG ? BASE_URL_DEBUG : BASE_URL;
    let userActorRecord = [];
    let dayMarkedDone = false;
    let showMarkDoneButton = false;
    // let toolTipContent = null;
    let link = null;
    let tabKey = null;
    let productionDayName = null;
    let allImages = [];
    let markDoneButton = null;
    const dayMarkedDoneBy = [];
    const availableActors = [];
    let currentUser = {};
    // commented and replaced with the next line by Waqar as a quick fix for loading issues
    // const isLoading = isFetching || _.isNull(selectedProductionDay) || _.isUndefined(selectedProductionDay.productionDays);
    const isLoading = isFetching || _.isNull(selectedProduction) || _.isUndefined(selectedProduction.productionDays);

    const {
        location
    } = this.props;

    if (!isLoading) {
        showMarkDoneButton = true;
        const selectedProductionDayLocal = selectedProduction.productionDays.filter((day) => day.slug === location.pathname.split('/')[3])[0];
        // commented and replaced with the next line by Waqar as a quick fix for loading issues
        // productionDayName = selectedProductionDay.productionDays && selectedProductionDay.productionDays[0] && selectedProductionDay.productionDays[0].name;
        productionDayName = selectedProductionDayLocal.name;
        currentUser = selectedProduction.currentUser;
        productionDayName = productionDayName.length > 99 ? `${productionDayName.substring(0, 96)}...` : productionDayName;
        currentUser = selectedProduction.currentUser;
        // commented and replaced with the next line by Waqar as a quick fix for loading issues
        // link = `/production/${selectedProductionDay.slug}`;
        link = `/production/${selectedProduction.slug}`;

        // commented and replaced with the next line by Waqar as a quick fix for loading issues
        // userActorRecord = _.filter(selectedProductionDay.productionDays[0].productionActors, (actor) => parseInt(actor.id, 10) === parseInt(user.UID, 10));
        userActorRecord = _.filter(selectedProductionDayLocal.productionActors, (actor) => parseInt(actor.id, 10) === parseInt(user.UID, 10));
        // commented and replaced with the next line by Waqar as a quick fix for loading issues
        // dayMarkedDone = selectedProductionDay.productionDays[0].markedDoneFlag === true; // || userActorRecord[0].markDone == true;
        dayMarkedDone = selectedProductionDayLocal.markedDoneFlag === true; // || userActorRecord[0].markDone == true;
        if (currentUser.user_type === ROLE_ACTOR && userActorRecord && userActorRecord[0] && userActorRecord[0].markedDone && userActorRecord[0].markedDone === true && dayMarkedDone === false) {
            dayMarkedDone = true;
            showMarkDoneButton = false;
        } else {
            let countUser = 0;
            // commented and replaced with the next line by Waqar as a quick fix for loading issues
            // _.each(selectedProductionDay.productionDays[0].productionActors, (actor) => {
            _.each(selectedProductionDayLocal.productionActors, (actor) => {
                if (currentUser.user_type !== ROLE_ACTOR) {
                    availableActors.push({ id: actor.id, name: actor.name });
                }
                if (actor.markedDone === true) {
                    if (currentUser.user_type !== ROLE_ACTOR) {
                        dayMarkedDoneBy.push(actor.name);
                    }
                    countUser += 1;
                }
            });
            // If all actors have marked the day as done, then make it done for everyone
            // commented and replaced with the next line by Waqar as a quick fix for loading issues
            // if (countUser > 0 && countUser === (selectedProductionDay.productionDays[0].productionActors).length) {
            if (countUser > 0 && countUser === (selectedProductionDayLocal.productionActors).length) {
                dayMarkedDone = true;
                showMarkDoneButton = false;
            }
        }
        // commented and replaced with the next line by Waqar as a quick fix for loading issues
        // allImages = _.map(selectedProductionDay.productionDays, (productionDay) => _.map(productionDay.images, (image) => {
        allImages = _.map(selectedProductionDayLocal.images, (image) => {
            const userViewRecord = _.filter(image.views, (view) => parseInt(view.user_id, 10) === user.UID);
            let iconClass = 'eye-invisible';
            let iconTitle = 'Unviewed';
            let status = 'inactive';

            const isKilled = image.isKilled === true && (currentUser.user_type !== ROLE_ACTOR || image.views.some((v) => v.killed === 1 && v.user_id === user.UID));
            const isViewed = userViewRecord[0] && userViewRecord[0].viewed_on && !_.isNull(userViewRecord[0].viewed_on);

            // console.log('::::::::::', isKilled, user.role, user.UID);

            if (isKilled) {
                iconClass = 'close';
                iconTitle = 'Killed';
                status = 'killed';
            } else if (isViewed) {
                iconClass = 'eye';
                iconTitle = 'Viewed';
                status = 'viewed';
            }

            if (parseInt(image.status, 10) === 2) {
                status = 'removed';
                iconTitle = 'Killed and excluded';
            }

            return ({
                id: image.id,
                name: image.name,
                // commented and replaced with the next line by Waqar as a quick fix for loading issues
                // link: `/production/${selectedProductionDay.slug}/${selectedProductionDay.productionDays[0].slug}/${image.name}`,
                link: `/production/${selectedProduction.slug}/${selectedProductionDayLocal.slug}/${image.name}`,
                picUrl: `${API_BASE_URL}/display-image?key=${image.publicUrl}&thumb=true,.jpg`,
                tags: image.tags,
                isSelected: image.is_selected,
                hasImageActiveCaption: image.hasImageActiveCaption,
                status,
                iconClass,
                iconTitle
            });
        });

        // commented and replaced with the next line by Waqar as a quick fix for loading issues
        // allImages = allImages[0] ? allImages[0] : allImages;
        markDoneButton = dayMarkedDone === false && showMarkDoneButton === true ? <ButtonIcon text="Mark day as done" icon="checked" color="red" width="large" onClick={this.showConfirm} /> : null;

        if (isUndefined(location.state)) {
            tabKey = 'unviewed';
        } else if (isUndefined(location.state.tabKey) || isNull(location.state.tabKey) || !tabNames.includes(location.state.tabKey)) {
            tabKey = 'unviewed';
        } else {
            tabKey = location.state.tabKey;
        }
    }

    const customCol = () => (
        <Col xs={8} sm={6}>
            <div className="dayimage" style={{ width: '151.25px', height: '151.25px', backgroundColor: '#999' }}>
                <img src={imagePlaceholder} alt="1" width="100%" />
            </div>
        </Col>
    );
    function CustomRow() {
        return (
            <Row gutter={1} type="flex">
                {customCol}
                {customCol}
                {customCol}
                {customCol}
            </Row>
        );
    }

    return (
        <div className="page-wrap reviewday">
            <Helmet title="Select a day" />
            <div className="header">
                <div className="container">
                    <div className="header__inner">
                        <Row type="flex" justify="space-between" align="middle">
                            <Col span={17}>
                                <div className="flex-center-stretch">
                                    <Menu />
                                    <Link to={link} onlyActiveOnIndex>
                                        <Icon name="arrowback" className="even-height" />
                                    </Link>
                                    {productionDayName}
                                    {dayMarkedDone ? <span style={{ fontSize: 18, color: '#00FF00', marginLeft: 10 }}><Icon name="checked" /></span> : null }
                                </div>
                            </Col>
                            <Col span={7}>
                                <AllowanceWidget />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            {!isLoading
                ? (
                    <Tabs
                        defaultActiveKey={tabKey}
                        onChange={() => localStorage.setItem('scrollYPosition', 0)}
                        className="ant-tabs--styled">
                        <Tabs.TabPane tab="Unviewed" key="unviewed">
                            <Spin spinning={isLoading}>
                                <Dayimages
                                    availableActors={availableActors}
                                    dayMarkedDoneBy={dayMarkedDoneBy}
                                    status="inactive"
                                    images={_.filter(allImages, (image) => (image.status !== 'killed' && image.status !== 'removed' && image.status !== 'viewed')).map((item) => ({
                                        ...item,
                                        status: 'inactive',
                                    }))}
                                    imagesCount={allImages.length}
                                    markDoneButton={markDoneButton} />
                            </Spin>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Viewed" key="viewed">
                            <Spin spinning={isLoading}>
                                <Dayimages
                                    availableActors={availableActors}
                                    dayMarkedDoneBy={dayMarkedDoneBy}
                                    status="viewed"
                                    images={_.filter(allImages, (image) => image.status === 'viewed')}
                                    imagesCount={allImages.length}
                                    markDoneButton={markDoneButton} />
                            </Spin>
                        </Tabs.TabPane>
                        {currentUser.user_type !== ROLE_VIEWER || currentUser.SelectImageViewOption === IMAGE_VIEW_ALL
                            ? (
                                <Tabs.TabPane tab="Killed" key="killed">
                                    <Spin spinning={isLoading}>
                                        <Dayimages
                                            availableActors={availableActors}
                                            dayMarkedDoneBy={dayMarkedDoneBy}
                                            status="killed"
                                            images={_.filter(allImages, (image) => image.status === 'killed' || image.status === 'removed')}
                                            imagesCount={allImages.length}
                                            markDoneButton={markDoneButton} />
                                    </Spin>
                                </Tabs.TabPane>
                            ) : null}
                        {currentUser.user_type !== ROLE_VIEWER || currentUser.SelectImageViewOption === IMAGE_VIEW_ALL
                            ? (
                                <Tabs.TabPane tab="All" key="all">
                                    <Spin spinning={isLoading}>
                                        <Dayimages
                                            availableActors={availableActors}
                                            dayMarkedDoneBy={dayMarkedDoneBy}
                                            status="all"
                                            images={allImages}
                                            imagesCount={allImages.length}
                                            markDoneButton={markDoneButton} />
                                    </Spin>
                                </Tabs.TabPane>
                            )
                            : null}
                    </Tabs>
                )
                : (
                    <div className="container">
                        <Spin spinning={isLoading}>
                            <div style={{ marginTop: '40px', opacity: 0.5 }}>
                                {CustomRow}
                                {CustomRow}
                                {CustomRow}
                                {CustomRow}
                                {CustomRow}
                            </div>
                        </Spin>
                    </div>
                )}
        </div>
    );
}
