import React from 'react';
import Helmet from 'react-helmet';
import {
    Tabs, Row, Col, Spin, Modal, Progress
} from 'antd';
import _ from 'underscore';
import SelectDayList from '../../components/SelectDayList';
import Menu from '../../components/Menu';
import AllowanceWidget from '../../components/AllowanceWidget';
import { ROLE_VIEWER } from '../../core/utils/value';

const { TabPane } = Tabs;

export default function ({
    /* eslint-disable-next-line react/prop-types */
    selectedProduction, isFetching,
}) {
    let allDays = [];
    let toReviewDays = [];
    let doneDays = [];
    const allActors = [];
    let currentUser = {};

    const { progressCounter } = this.state;

    if (!isFetching && !_.isNull(selectedProduction) && selectedProduction.productionDays) {
        currentUser = selectedProduction.currentUser;
        allDays = _.map(selectedProduction.productionDays, (productionDay) => {
            const dayMarkedDone = productionDay.markedDoneFlag;
            _.each(productionDay.productionActors, (productionActor) => {
                const checkActor = _.find(allActors, (actor) => actor.name === productionActor.name);
                if (_.isUndefined(checkActor)) {
                    allActors.push({ id: productionActor.id, name: productionActor.name });
                }
            });
            return ({
                id: productionDay.id,
                name: productionDay.name,
                checked: (dayMarkedDone === true ? 'checked' : ''),
                viewedStats: productionDay.viewedStats,
                killed: productionDay.killed,
                doneBy: productionDay.markedDoneBy,
                waitingFor: productionDay.markedNotDoneBy,
                productionSlug: selectedProduction.slug,
                daySlug: productionDay.slug
            });
        });

        toReviewDays = _.filter(allDays, (day) => day.checked === '');
        doneDays = _.filter(allDays, (day) => day.checked === 'checked');
    }

    return (
        <Spin spinning={isFetching || _.isNull(selectedProduction)} tip="">
            <Modal
                title="Processing, may take up to 10 second"
                footer={null}
                closable={false}
                visible={isFetching || _.isNull(selectedProduction)}>
                <div style={{ textAlign: 'center' }}>
                    <Progress type="circle" percent={progressCounter} />
                </div>
            </Modal>
            <div className="page-wrap selectday">
                <Helmet title="Select a day" />
                <div className="header">
                    <div className="container">
                        <div className="header__inner">
                            <Row type="flex" justify="space-between" align="middle">
                                <Col span={14}>
                                    <div className="flex-center-stretch">
                                        <Menu />
                                        Select a day to review
                                    </div>
                                </Col>
                                <Col span={10}>
                                    <AllowanceWidget />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <Tabs defaultActiveKey="1" className="ant-tabs--styled">
                    <TabPane tab="All" key="1">
                        <SelectDayList
                            days={allDays} />
                    </TabPane>
                    {currentUser.user_type !== ROLE_VIEWER ? (
                        <TabPane tab="To review" key="2">
                            <SelectDayList
                                days={toReviewDays} />
                        </TabPane>
                    ) : null}
                    {currentUser.user_type !== ROLE_VIEWER ? (
                        <TabPane tab="Done" key="3">
                            <SelectDayList
                                days={doneDays} />
                        </TabPane>
                    ) : null}
                </Tabs>
                <div style={{ textAlign: 'center', marginTop: 10 }}>
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            this.startProgressCounter();
                            const { actions, params } = this.props;
                            actions.getUserSingleProductionRequest({
                                slug: params.production,
                                invalidateCache: true
                            });
                        }}>Fetch and Recalculate Totals</a>
                </div>
            </div>
        </Spin>
    );
}
