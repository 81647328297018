import React from 'react';
import PropTypes from 'prop-types';
import logoSrcTransparent from '../assets/images/ia-logo-bg-transparent.png';
import logoSrcWhite from '../assets/images/logo-white.png';
import logoSrcBlack from '../assets/images/logo-black.png';

export default function LogoWhite(props) {
    let logoSrc = logoSrcTransparent;
    if (props.color === 'black') {
        logoSrc = logoSrcBlack;
    } else if (props.color === 'white') {
        logoSrc = logoSrcWhite;
    }
    return <img className="github" src={logoSrc} color={props.color} width={props.width} alt="Logo" />;
}
LogoWhite.defaultProps = {
    width: 'auto',
    color: 'transparent',
};
LogoWhite.propTypes = {
    width: PropTypes.string,
    color: PropTypes.string,
};
