import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Form } from 'antd';
import { connect } from 'react-redux';
import * as userActions from '../../core/user/userActions';
import DOM from './forgotPassword';

class ForgotPasswordForm extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            showModal: false,
            showEmailModal: false,
        };
    }

    componentDidUpdate(prevProps) {
        // Check if isUsername or resetPasswordSuccess has changed
        if (this.props.isUsername !== prevProps.isUsername || this.props.resetPasswordSuccess !== prevProps.resetPasswordSuccess) {
            const { isUsername, resetPasswordSuccess, actions } = this.props;
            if (isUsername) {
                this.setState({
                    showModal: true,
                });
                actions.passwordResetState();
            } else if (resetPasswordSuccess) {
                this.setState({
                    showEmailModal: true,
                });
                actions.passwordResetState();
            }
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                const { actions } = this.props;
                await actions.passwordResetRequest({
                    email: values.email,
                });
            }
        });
    };

    handleCancel = () => {
        this.setState({
            showModal: false,
            showEmailModal: false,
        });
    }

    render() {
        return this.view();
    }
}

ForgotPasswordForm.propTypes = {
    actions: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
};

const ForgotPassword = Form.create()(ForgotPasswordForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        user: state.user.user,
        isUsername: state.user.isUsername,
        resetPasswordSuccess: state.user.resetPasswordSuccess,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...userActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ForgotPassword);
