import React from 'react';
import PropTypes from 'prop-types';
import spriteSrc from '../../assets/images/sprite.svg';

function ButtonIcon(props) {
    return (
        <button type="button" className={`buttonicon ${props.color} ${props.width} ${props.extraClass}`} onClick={props.onClick}>
            <svg className={`icon icon-${props.icon}`}>
                <use xlinkHref={`${spriteSrc}#icon-${props.icon}`} />
            </svg>
            <span>{props.text}</span>
        </button>
    );
}
ButtonIcon.defaultProps = {
    text: '',
    icon: '',
    width: '',
    color: '',
    extraClass: '',
    onClick: null,
};

ButtonIcon.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.string,
    width: PropTypes.string,
    color: PropTypes.string,
    extraClass: PropTypes.string,
    onClick: PropTypes.func,
};
export default ButtonIcon;
