import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as globalActions from '../../core/global/globalActions';
import * as productionActions from '../../core/production/productionActions';
import * as userActions from '../../core/user/userActions';

import DOM from './production';
import { ROLE_ROOT_ADMIN } from '../../core/utils/value';

class Production extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {
        const { actions, user } = this.props;
        actions.getUserProductionRequest();

        actions.resetSelectedActorRequest();

        if (user.role === ROLE_ROOT_ADMIN) {
            actions.getAllResellersRequest();
        }
    }

    handleLogout = (e) => {
        e.preventDefault();
        const { actions } = this.props;
        actions.logoutRequest();
    }

    filterProductionList = (resellerId) => {
        const { actions } = this.props;
        actions.getUserProductionRequest({
            resellerId,
        });
    }

    render() {
        return this.view();
    }
}

Production.defaultProps = {
    user: {},
};

Production.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        productions: state.production.productions,
        user: state.user.user,
        resellersList: state.production.resellersList,
        resellerListFetching: state.production.resellerListFetching,
        isFetching: state.production.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...productionActions,
                ...userActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Production);
