import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as globalActions from '../../core/global/globalActions';
import * as productionActions from '../../core/production/productionActions';

import DOM from './downloads';

class Downloads extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {
        const { actions, params } = this.props;
        actions.getUserSingleProductionRequest({
            slug: params.production,
        });
        actions.getDownloadQueueRequest({
            productionSlug: params.production,
        });
    }

    downloadFile = (listType, file, fileType, actionType) => {
        const { actions, params } = this.props;
        actions.getDownloadFileRequest({
            productionSlug: params.production,
            listType,
            file,
            fileType,
            actionType,
        });
    }

    render() {
        return this.view();
    }
}

Downloads.defaultProps = {
    params: {},
};

Downloads.propTypes = {
    actions: PropTypes.object.isRequired,
    params: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedProduction: state.production.selectedProduction,
        user: state.user.user,
        isFetching: state.production.isFetching,
        downloadQueue: state.production.downloadQueue,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...productionActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Downloads);
