import React from 'react';
import PropTypes from 'prop-types';
import { findIndex } from 'underscore';
import DOM from './activeDaySelector';

class ActiveDaySelector extends React.Component {
    constructor(props) {
        super(props);

        const navigationData = this.getPreviousAndNextDays(props.value, props.days);

        this.state = {
            previousDay: navigationData.previousDay,
            nextDay: navigationData.nextDay,
        };

        this.view = DOM;
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):

        if (this.props.value !== prevProps.value) {
            const navigationData = this.getPreviousAndNextDays(this.props.value, this.props.days);
            this.setState({
                previousDay: navigationData.previousDay,
                nextDay: navigationData.nextDay,
            });
        }
    }

    getPreviousAndNextDays(currentValue, days) {
        let previousDay = 0;
        let nextDay = 0;

        if (currentValue === 0) {
            nextDay = days[0].id;
        } else {
            const dayIndex = findIndex(days, (val) => val.id === currentValue);
            if (dayIndex !== -1) {
                if (days[dayIndex - 1]) {
                    previousDay = days[dayIndex - 1].id;
                }
                if (days[dayIndex + 1]) {
                    nextDay = days[dayIndex + 1].id;
                }
            }
        }

        return {
            previousDay,
            nextDay,
        };
    }

    changeActiveDay(dayId) {
        const { days, onChange } = this.props;

        const dayIndex = findIndex(days, (val) => val.id === dayId);
        if (dayIndex !== -1) {
            onChange(days[dayIndex]);
        }
    }

    render() {
        return this.view();
    }
}

ActiveDaySelector.defaultProps = {
    markDoneButton: null,
    selectedActor: null,
    status: 'all',
    value: 0,
};

ActiveDaySelector.propTypes = {
    days: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number,
};

export default ActiveDaySelector;
