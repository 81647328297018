/* eslint-disable react/no-this-in-sfc */

import React from 'react';
import {
    Tooltip, Row, Col, Spin, Empty, Alert, Select, Icon
} from 'antd';
import { Link } from 'react-router';
import Img from 'react-image';
import _ from 'underscore';
import LazyLoad from 'react-lazyload';
import './dayimages.css';
import { IMAGE_VIEW_ALL, ROLE_VIEWER, ROLE_ACTOR } from '../../core/utils/value';

export default function () {
    const {
        markDoneButton, status, dayMarkedDoneBy, user, availableActors, selectedActor, imagesCount, selectedProduction
    } = this.props;
    const { currentUser } = selectedProduction;
    const { images, scrollYPosition } = this.state;
    const optionsList = _.map(availableActors, (actor) => (<Select.Option key={actor.id} value={`${actor.id}-${actor.name}`}>{actor.name}</Select.Option>));
    optionsList.push(<Select.Option key="without-actor" value={`${null}-nobody`}>Without Actors</Select.Option>);
    optionsList.push(<Select.Option key="all-actors" value={null}>All Actors</Select.Option>);
    const imagePlaceHolder = (
        <div className="dayimage-spinner-container" style={{ display: 'flex', minHeight: '151px', justifyContent: 'center' }}>
            <Spin style={{ alignSelf: 'center' }} />
        </div>
    );

    const selectedActorValue = selectedActor ? `${selectedActor.id}-${selectedActor.name}` : null;
    return (
        <div className="dayimage-wrap">
            <div className="container">
                {currentUser.user_type !== ROLE_VIEWER && dayMarkedDoneBy && dayMarkedDoneBy.length > 0
                    ? (
                        <div style={{ marginBottom: 20 }}>
                            <Alert
                                showCustomIcon
                                message="Day marked done by"
                                description={dayMarkedDoneBy.join(', ')}
                                type="info" />
                        </div>
                    )
                    : null}
                {currentUser.user_type !== ROLE_VIEWER
                    ? (
                        <div className="btn__mark-done">
                            {markDoneButton}
                        </div>
                    ) : null}
                {currentUser.user_type !== ROLE_ACTOR
                    ? (
                        <div style={{ marginBottom: 20 }}>
                            <Row style={{ marginBottom: 10 }}>
                                <Col xs={24}><strong>Filter By Actor</strong></Col>
                            </Row>
                            <Row>
                                <Col xs={24}>
                                    <Select
                                        showSearch
                                        allowClear
                                        defaultValue={selectedActorValue}
                                        value={selectedActorValue}
                                        // loading={resellerListFetching}
                                        style={{ width: '100%' }}
                                        placeholder="Select an actor"
                                        optionFilterProp="children"
                                        onChange={(value) => this.selectActor(value)}
                                        // onFocus={handleFocus}
                                        // onBlur={handleBlur}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {optionsList}
                                    </Select>
                                </Col>
                            </Row>
                        </div>
                    )
                    : null}
                <Row gutter={1} type="flex">
                    {images.length > 0 /* eslint-disable-line no-nested-ternary */
                        ? _.map(images, (dayImage, dayImageIndex) => (
                            <Col xs={8} lg={6} key={dayImage.id}>
                                <LazyLoad debounce={500} height={200} once key={dayImageIndex} placeholder={imagePlaceHolder}>
                                    <div
                                        id={`dayimage-${dayImage.id}`}
                                        className={`dayimage ${dayImage.status}`}>
                                        <Link
                                            onClick={() => {
                                                localStorage.setItem('dayImageScrollId', dayImage.id);
                                                localStorage.setItem('scrollYPosition', scrollYPosition);
                                                window.dispatchEvent(new Event('storage'));
                                            }}
                                            to={`${dayImage.link}/${status}`}
                                            className="dayimage-spinner-container"
                                            style={{ minHeight: '151px' }}>
                                            <Img
                                                style={{ minHeight: '151px' }}
                                                height={200}
                                                loader={imagePlaceHolder}
                                                src={dayImage.picUrl}
                                                className="dayimage__pic"
                                                crossOrigin="anonymous" />
                                            <div className="image-single-action-row-small">
                                                {dayImage.hasImageActiveCaption === true && currentUser.user_type !== ROLE_ACTOR && currentUser.user_type !== ROLE_VIEWER
                                                    ? (
                                                        <div className="image-single-action-icon green">
                                                            <Tooltip title="Image has caption">
                                                                <Icon theme="filled" type="message" />
                                                            </Tooltip>
                                                        </div>
                                                    )
                                                    : null}
                                                {parseInt(dayImage.isSelected, 10) === 1 && currentUser.user_type !== ROLE_ACTOR && currentUser.user_type !== ROLE_VIEWER
                                                    ? (
                                                        <div className="image-single-action-icon orange">
                                                            <Tooltip title="Image Selected">
                                                                <Icon theme="filled" type="star" />
                                                            </Tooltip>
                                                        </div>
                                                    )
                                                    : null}
                                                {currentUser.user_type !== ROLE_VIEWER || currentUser.SelectImageViewOption === IMAGE_VIEW_ALL
                                                    ? (
                                                        <div className={`image-single-action-icon ${dayImage.status === 'killed' || (dayImage.status === 'inactive' && dayImage.iconTitle === 'Killed') ? 'red' : dayImage.status === 'removed' ? 'black' : 'grey'}`}>
                                                            <Tooltip title={dayImage.iconTitle}>
                                                                <Icon type={dayImage.iconClass} />
                                                            </Tooltip>
                                                        </div>
                                                    )
                                                    : null}
                                            </div>
                                        </Link>
                                    </div>
                                </LazyLoad>
                            </Col>
                        ))
                        : (imagesCount >= 0 && status === 'inactive'
                            ? (
                                <div style={{ width: '100%', margin: '40px auto', textAlign: 'center' }}>
                                    <span style={{ display: 'block', marginTop: '60px' }}>
                                        <span style={{ display: 'block' }}>You have viewed all images for this day.</span>
                                        <span style={{ display: 'block', marginTop: '10px' }}>
                                            Go to the
                                            {' '}
                                            <strong>&#39;All&#39;</strong>
                                            ,
                                            <strong>&#39;Viewed&#39;</strong>
                                            ,
                                            or
                                            <strong>&#39;Killed&#39;</strong>
                                            {' '}
                                            tabs if you would like to review the images for this day.
                                        </span>
                                    </span>
                                </div>
                            )
                            : (
                                <div style={{ width: '100%', margin: '65px auto' }}>
                                    <Empty
                                        description={<span>No images found</span>} />
                                </div>
                            )
                        )}
                </Row>
                {currentUser.user_type !== ROLE_VIEWER
                    ? (
                        <div className="btn__mark-done" style={{ marginTop: '35px' }}>
                            {markDoneButton}
                        </div>
                    ) : null}
            </div>
        </div>
    );
}
