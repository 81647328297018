import React from 'react';
import _ from 'underscore';
import { Link } from 'react-router';
import {
    Row, Col, Select, Empty
} from 'antd';

import './cardList.css';
import { ROLE_ROOT_ADMIN } from '../../core/utils/value';

export default function () {
    const {
        cards, user, filterProductionList, resellersList, resellerListFetching
    } = this.props;

    const optionsList = _.map(resellersList, (reseller) => (<Select.Option key={reseller.id} value={reseller.id}>{`${reseller.title}`}</Select.Option>));
    return (
        <div className="card-list">
            <div className="container">
                {user.role === ROLE_ROOT_ADMIN
                    ? (
                        <div style={{ marginBottom: 20 }}>
                            <Row style={{ marginBottom: 10 }}>
                                <Col xs={24}><strong>Filter By Reseller</strong></Col>
                            </Row>
                            <Row>
                                <Col xs={24}>
                                    <Select
                                        showSearch
                                        allowClear
                                        loading={resellerListFetching}
                                        style={{ width: '100%' }}
                                        placeholder="Select a reseller"
                                        optionFilterProp="children"
                                        onChange={(value) => filterProductionList(value)}
                                        // onFocus={handleFocus}
                                        // onBlur={handleBlur}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {optionsList}
                                    </Select>
                                </Col>
                            </Row>
                        </div>
                    )
                    : null}

                <div style={{ marginBottom: 20 }}>
                    <Row>
                        <Col xs={24}><strong>Select a Production:</strong></Col>
                    </Row>
                </div>

                <Row>
                    {cards.length > 0
                        ? (
                            <Col xs={24}>
                                {cards.map((card) => {
                                    const link = `/production/${card.slug}`;
                                    return (
                                        <div className="card" key={card.id}>
                                            <Link to={link}>
                                                <figure className="card__figure">
                                                    <img className="card__pic" src={card.picUrl} alt={card.name} />
                                                    <figcaption className="card__name">{card.name}</figcaption>
                                                </figure>
                                                <div className="card__text">
                                                    <Row>
                                                        <Col span={6}>
                                                            <div className="card__data">
                                                                <span className="text-bold">{card.days}</span>
                                                                <p>Days</p>
                                                            </div>
                                                        </Col>
                                                        <Col span={6}>
                                                            <div className="card__data">
                                                                <span className="text-bold">{card.reviewed}</span>
                                                                <p>Viewed</p>
                                                            </div>
                                                        </Col>
                                                        <Col span={6}>
                                                            <div className="card__data">
                                                                <span className="text-bold">{card.unreviewed}</span>
                                                                <p>Unviewed</p>
                                                            </div>
                                                        </Col>
                                                        <Col span={6}>
                                                            <div className="card__data">
                                                                <span className="text-bold">{card.killed}</span>
                                                                <p>Killed</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                })}
                            </Col>
                        )
                        : (
                            <Col xs={24} style={{ margin: '30px auto' }}>
                                {user.role === ROLE_ROOT_ADMIN
                                    ? (
                                        <Empty
                                            description={<span>No productions found</span>} />
                                    )
                                    : (
                                        <Empty
                                            description={(
                                                <span>
                                                    <span style={{
                                                        fontSize: '18px', fontWeight: 'bold', display: 'block', margin: '10px auto'
                                                    }}>
                                                        You're currently not allocated to a production.
                                                    </span>
                                                    <span>
                                                        The unit stills photographer must add you to a production on request of the producer or PR department.
                                                        if you think a production should display here that you cannot see then please contact your line manager.
                                                    </span>
                                                </span>
                                            )} />
                                    )}
                            </Col>
                        )}
                </Row>
            </div>
        </div>
    );
}
