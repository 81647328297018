import React from 'react';
import {
    Form, Input, Button, Row, Col
} from 'antd';
import Helmet from 'react-helmet';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const FormItem = Form.Item;

export default function () {
    const { getFieldDecorator } = this.props.form;
    return (
        <div className="page-wrap">
            <Helmet title="Reset Password" />
            <Header title="Reset Password" handleLogout={null} />
            <div className="container">
                <Form onSubmit={this.handleSubmit} layout="vertical">
                    <Row style={{ marginTop: '40px', marginBottom: '10px' }}>
                        <Col xs={24}>
                            <FormItem label="New Password">
                                {getFieldDecorator('password', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please enter new password'
                                        },
                                        {
                                            validator: this.validateToNextPassword,
                                        }
                                    ]
                                })(<Input type="password" />)}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col xs={24}>
                            <FormItem label="Confirm New Password">
                                {getFieldDecorator('confirmPassword', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please enter confirm new password'
                                        },
                                        {
                                            validator: this.compareToFirstPassword,
                                        }
                                    ]
                                })(<Input type="password" />)}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} style={{ textAlign: 'center' }}>
                            <FormItem>
                                <Button type="red" htmlType="submit">
                                    Reset Password
                                </Button>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
                <Footer />
            </div>
        </div>
    );
}
