import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { isEmpty } from 'underscore';
import DOM from './imageCaptionArea';

import * as globalActions from '../../core/global/globalActions';
import * as productionActions from '../../core/production/productionActions';

class ImageCaptionArea extends Component {
    constructor(props) {
        super(props);

        this.state = {
            caption: '',
            historyKey: 0,
        };

        this.view = DOM;
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):

        if (this.props.selectedProductionDayImage !== prevProps.selectedProductionDayImage) {
            let caption = '';
            let historyKey = 0;

            if (this.props.selectedProductionDayImage.captions[0] && this.props.selectedProductionDayImage.captions[0].caption && parseInt(this.props.selectedProductionDayImage.captions[0].status, 10) === 1) {
                caption = this.props.selectedProductionDayImage.captions[0].caption;
            }

            if (this.props.selectedProductionDayImage.captions[0] && parseInt(this.props.selectedProductionDayImage.captions[0].status, 10) !== 1) {
                historyKey = -1;
            }

            this.setState({
                caption,
                historyKey,
            });
        }
    }

    saveImageCaption() {
        const { caption } = this.state;
        const { actions, selectedProductionDayImage, nextImage } = this.props;

        actions.updateImageCaptionRequest({
            imageId: selectedProductionDayImage.id,
            productionSlug: selectedProductionDayImage.production.slug,
            productionDaySlug: selectedProductionDayImage.production_day.slug,
            imageName: selectedProductionDayImage.name,
            caption,
        });

        if (!isEmpty(nextImage)) {
            browserHistory.push(nextImage);
        }
    }

    loadCaptionHistory() {
        const { historyKey, caption } = this.state;
        const { selectedProductionDayImage } = this.props;
        let nextKey = historyKey + 1;

        let nextCaption = caption;

        if (selectedProductionDayImage.captions[nextKey] && selectedProductionDayImage.captions[nextKey].caption) {
            nextCaption = selectedProductionDayImage.captions[nextKey].caption;
        } else {
            if (selectedProductionDayImage.captions[0] && selectedProductionDayImage.captions[0].caption) {
                nextCaption = selectedProductionDayImage.captions[0].caption;
            }
            nextKey = 0;
        }

        this.setState({
            caption: nextCaption,
            historyKey: nextKey,
        });
    }

    autoCaptionImage() {
        const { selectedProductionDayImage } = this.props;
        let users = '';

        if (!isEmpty(selectedProductionDayImage.users)) {
            selectedProductionDayImage.users.map((item, key) => {
                if (key === 0) {
                    users += item.full_name;
                } else {
                    users += `, ${item.full_name}`;
                }

                return null;
            });
        }

        const autoCaption = `${users} in ${selectedProductionDayImage.production.name}`;

        this.setState({
            caption: autoCaption,
        });
    }

    render() {
        return this.view();
    }
}

ImageCaptionArea.defaultProps = {
    selectedProductionDayImage: null,
    nextImage: '',
};

ImageCaptionArea.propTypes = {
    actions: PropTypes.object.isRequired,
    selectedProductionDayImage: PropTypes.object,
    nextImage: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedProductionDayImage: state.production.selectedProductionDayImage,
        imageCaptionModeOn: state.global.imageCaptionModeOn,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...productionActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ImageCaptionArea);
