import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    SESSION_TOKEN_REQUEST: null,
    SESSION_TOKEN_SUCCESS: null,
    SESSION_TOKEN_FAILURE: null,

    DELETE_TOKEN_REQUEST: null,
    DELETE_TOKEN_SUCCESS: null,

    SAVE_TOKEN_REQUEST: null,
    SAVE_TOKEN_SUCCESS: null,

    LOGOUT_REQUEST: null,
    LOGOUT_SUCCESS: null,
    LOGOUT_FAILURE: null,

    LOGIN_REQUEST: null,
    LOGIN_SUCCESS: null,
    LOGIN_FAILURE: null,

    SIGNUP_REQUEST: null,
    SIGNUP_SUCCESS: null,
    SIGNUP_FAILURE: null,

    API_LOGIN_REQUEST: null,
    API_LOGIN_SUCCESS: null,

    CONTACT_REQUEST: null,
    CONTACT_SUCCESS: null,
    CONTACT_FAILURE: null,

    PASSWORD_RESET_REQUEST: null,
    PASSWORD_RESET_SUCCESS: null,
    PASSWORD_RESET_FAILURE: null,

    FORGOT_PASSWORD_VALID_LINK_REQUEST: null,
    FORGOT_PASSWORD_VALID_LINK_SUCCESS: null,
    FORGOT_PASSWORD_VALID_LINK_FAILURE: null,

    RESET_USER_PASSWORD_REQUEST: null,
    RESET_USER_PASSWORD_SUCCESS: null,
    RESET_USER_PASSWORD_FAILURE: null,

    USER_SETTINGS_REQUEST: null,
    USER_SETTINGS_SUCCESS: null,
    USER_SETTINGS_FAILURE: null,

    FIRST_TIME_LOGIN_REQUEST: null,
    FIRST_TIME_LOGIN_SUCCESS: null,
    FIRST_TIME_LOGIN_FAILURE: null,

    PASSWORD_RESET_STATE: null,
});

export const {
    deleteTokenRequest,
    deleteTokenSuccess,
} = createActions(constants.DELETE_TOKEN_REQUEST, constants.DELETE_TOKEN_SUCCESS);

export const {
    saveTokenRequest,
    saveTokenSuccess,
} = createActions(constants.SAVE_TOKEN_REQUEST, constants.SAVE_TOKEN_SUCCESS);

export const {
    logoutRequest,
    logoutSuccess,
    logoutFailure,
} = createActions(constants.LOGOUT_REQUEST, constants.LOGOUT_SUCCESS, constants.LOGOUT_FAILURE);

export const {
    signupRequest,
    signupSuccess,
    signupFailure,
} = createActions(constants.SIGNUP_REQUEST, constants.SIGNUP_SUCCESS, constants.SIGNUP_FAILURE);

export const {
    sessionTokenRequest,
    sessionTokenSuccess,
    sessionTokenFailure,
} = createActions(
    constants.SESSION_TOKEN_REQUEST,
    constants.SESSION_TOKEN_SUCCESS,
    constants.SESSION_TOKEN_FAILURE,
);

export const {
    loginRequest,
    loginSuccess,
    loginFailure,
} = createActions(constants.LOGIN_REQUEST, constants.LOGIN_SUCCESS, constants.LOGIN_FAILURE);

export const {
    apiLoginRequest,
    apiLoginSuccess,
} = createActions(constants.API_LOGIN_REQUEST, constants.API_LOGIN_SUCCESS);

export const {
    contactRequest,
    contactSuccess,
    contactFailure,
} = createActions(
    constants.CONTACT_REQUEST,
    constants.CONTACT_SUCCESS,
    constants.CONTACT_FAILURE,
);

export const {
    passwordResetRequest,
    passwordResetSuccess,
    passwordResetFailure,
} = createActions(
    constants.PASSWORD_RESET_REQUEST,
    constants.PASSWORD_RESET_SUCCESS,
    constants.PASSWORD_RESET_FAILURE,
);

export const {
    forgotPasswordValidLinkRequest,
    forgotPasswordValidLinkSuccess,
    forgotPasswordValidLinkFailure,
} = createActions(
    constants.FORGOT_PASSWORD_VALID_LINK_REQUEST,
    constants.FORGOT_PASSWORD_VALID_LINK_SUCCESS,
    constants.FORGOT_PASSWORD_VALID_LINK_FAILURE,
);

export const {
    resetUserPasswordRequest,
    resetUserPasswordSuccess,
    resetUserPasswordFailure,
} = createActions(
    constants.RESET_USER_PASSWORD_REQUEST,
    constants.RESET_USER_PASSWORD_SUCCESS,
    constants.RESET_USER_PASSWORD_FAILURE,
);

export const {
    userSettingsRequest,
    userSettingsSuccess,
    userSettingsFailure,
} = createActions(
    constants.USER_SETTINGS_REQUEST,
    constants.USER_SETTINGS_SUCCESS,
    constants.USER_SETTINGS_FAILURE,
);

export const {
    firstTimeLoginRequest,
    firstTimeLoginSuccess,
    firstTimeLoginFailure,
} = createActions(
    constants.FIRST_TIME_LOGIN_REQUEST,
    constants.FIRST_TIME_LOGIN_SUCCESS,
    constants.FIRST_TIME_LOGIN_FAILURE,
);

export const {
    passwordResetState,
} = createActions(
    constants.PASSWORD_RESET_STATE,
);
