import React from 'react';
import Helmet from 'react-helmet';
import {
    Button, Form, Input, Select, Row, Col, Spin
} from 'antd';
import { Link } from 'react-router';
import Header from '../../components/Header';
import Icon from '../../elements/Icon';

export default function () {
    const { isFetching, form } = this.props;
    const { getFieldDecorator } = form;
    const prefixSelector = getFieldDecorator('phonePrefix', {
        initialValue: '44',
    })(
        <Select style={{ width: 70 }}>
            <Select.Option value="44">+44</Select.Option>
        </Select>
    );

    const headerTitle = (
        <div>
            <Link to="/">
                <Icon name="arrowback" />
            </Link>
            <div style={{
                width: '90%', height: '21px', marginLeft: '23px', marginTop: '-23px'
            }}>
                Request access
            </div>
        </div>
    );
    return (
        <div className="page-wrap production">
            <Helmet title="Request access" />
            <Header title={headerTitle} handleLogout={null} />
            <div className="container" style={{ paddingTop: '40px' }}>
                <Spin spinning={isFetching}>
                    <Form onSubmit={this.handleSubmit} layout="vertical">
                        <Form.Item label="Full name *">
                            {getFieldDecorator('fullName', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input your full name'
                                    }
                                ]
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Email address *">
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input your email address!'
                                    },
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email address',
                                    }
                                ]
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Phone number">
                            {getFieldDecorator('phone', {
                                rules: [{ required: true, message: 'Please input your phone number!' }],
                            })(
                                <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                            )}
                        </Form.Item>
                        <Form.Item label="Production name">
                            {getFieldDecorator('productionName', {})(<Input />)}
                        </Form.Item>
                        <Form.Item label="Your job role *">
                            {getFieldDecorator('jobRole', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input your job role'
                                    }
                                ]
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Estimated number of shoot days with stills coverage *">
                            {getFieldDecorator('shootDays', {
                                initialValue: '1-10',
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please select number of shoot days'
                                    }
                                ]
                            })(<Select>
                                <Select.Option value="1-10">1-10</Select.Option>
                                <Select.Option value="11-20">11-20</Select.Option>
                                <Select.Option value="21-30">21-30</Select.Option>
                                <Select.Option value="31-40">31-40</Select.Option>
                                <Select.Option value="41+">41+</Select.Option>
                            </Select>)}
                        </Form.Item>
                        <Form.Item label="Estimated number of actors with approvals rights *">
                            {getFieldDecorator('cast', {
                                initialValue: '1',
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please select number of cast'
                                    }
                                ]
                            })(<Select>
                                <Select.Option value="1">1</Select.Option>
                                <Select.Option value="2">2</Select.Option>
                                <Select.Option value="3">3</Select.Option>
                                <Select.Option value="4">4</Select.Option>
                                <Select.Option value="5">5</Select.Option>
                                <Select.Option value="5+">5+</Select.Option>
                            </Select>)}
                        </Form.Item>
                        <Row>
                            <Col xs={24} style={{ textAlign: 'center' }}>In submitting you agree to our</Col>
                        </Row>
                        <Row>
                            <Col xs={24} style={{ textAlign: 'center' }}>
                                <Link className="link-red" to="terms-and-conditions">
                                    terms and conditions
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} style={{ textAlign: 'center', marginTop: '20px' }}>
                                <Form.Item>
                                    <Button type="red" htmlType="submit">
                                        Agree & Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </div>
        </div>
    );
}
