import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as userActions from '../../core/user/userActions';
import DOM from './loginform';

class UserLoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { actions } = this.props;
                actions.loginRequest({
                    username: values.email,
                    password: values.password,
                });
            }
        });
    };

    render() {
        return this.view();
    }
}

UserLoginForm.propTypes = {
    form: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
};

const LoginForm = Form.create()(UserLoginForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        global: state.global,
        isLoggedIn: state.user.isLoggedIn,
        actions: state.actions,
        isFetching: state.user.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
