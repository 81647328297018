import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'underscore';

import DOM from './login';
import * as globalActions from '../../core/global/globalActions';
import * as userActions from '../../core/user/userActions';

class Login extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentWillMount() {
        const { actions, params } = this.props;
        if (!_.isNull(params.autoLoginKey) && !_.isUndefined(params.autoLoginKey) && params.autoLoginKey !== '') {
            actions.firstTimeLoginRequest({
                loginKey: params.autoLoginKey,
            });
        }
    }

    componentDidMount() {
        const { actions } = this.props;

        // set breadcrumb
        actions.setBreadcrumb([{ title: 'Login' }]);
    }

    render() {
        return this.view();
    }
}

Login.defaultProps = {
    params: {},
};

Login.propTypes = {
    actions: PropTypes.object.isRequired,
    params: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.user.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...userActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Login);
