import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';
import DOM from './requestAccess';
import * as productionActions from '../../core/production/productionActions';

class RequestAccessForm extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { actions } = this.props;
                actions.sendAccessRequestRequest(values);
            }
        });
    }

    render() {
        return this.view();
    }
}

RequestAccessForm.propTypes = {
    actions: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
};

const RequestAccess = Form.create()(RequestAccessForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.production.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...productionActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RequestAccess);
