/* global window: true */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { notification } from 'antd';
import {
    isUndefined, findIndex, map, each, isNull
} from 'underscore';

import * as globalActions from '../../core/global/globalActions';
import * as productionActions from '../../core/production/productionActions';

import DOM from './selects';
import { ROLE_ACTOR } from '../../core/utils/value';

const {
    APP_DEBUG,
    BASE_URL,
    BASE_URL_DEBUG,
} = require('../../core/constants').default;

class Selects extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            killedDays: [],
            unkilledDays: [],
            selectedKilledDay: {},
            selectedUnkilledDay: {},
        };
    }

    componentDidMount() {
        const { actions, params } = this.props;
        actions.getUserSingleProductionRequest({
            slug: params.production,
        });
        this.checkSelectedDay();
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if ((this.props.selectedProduction !== prevProps.selectedProduction) && !this.props.isFetching) {
            console.log('::::::::::::: selectedProduction changed :::::::::::::::');
            this.filterKilledImages();
            this.filterUnkilledImages();
            this.checkSelectedDay();
        }
    }

    checkSelectedDay() {
        const { activeTab, activeDay } = this.props;
        const { killedDays, unkilledDays } = this.state;

        if (activeDay !== '') {
            if (activeTab === 'select') {
                const dayIndex = findIndex(killedDays, (val) => val.slug === activeDay);
                if (dayIndex !== -1) {
                    this.setState({
                        selectedKilledDay: killedDays[dayIndex],
                    });
                }
            }

            if (activeTab === 'unkilled-select') {
                this.displayUnkilledTabNotification();
                const dayIndex = findIndex(unkilledDays, (val) => val.slug === activeDay);
                if (dayIndex !== -1) {
                    this.setState({
                        selectedUnkilledDay: unkilledDays[dayIndex],
                    });
                }
            }
        }
    }

    filterKilledImages() {
        const { selectedProduction, user, isFetching, params } = this.props;
        let currentUser = {};
        const { killedDays } = this.state;
        if (!isFetching && !isNull(selectedProduction)) {
            currentUser = selectedProduction.currentUser;
        }

        map(selectedProduction?.productionDays, (day) => {
            const images = [];
            let count = 0;
            each(day.images, (image) => {
                let isKilled = false;
                each(image.views, (view) => {
                    if (parseInt(view.killed, 10) === 1) {
                        if (currentUser.user_type === ROLE_ACTOR && parseInt(user.UID, 10) === parseInt(view.user_id, 10)) {
                            isKilled = true;
                        } else if (currentUser.user_type !== ROLE_ACTOR) {
                            isKilled = true;
                        }
                    }
                });

                if (image.is_selected && parseInt(image.is_selected, 10) === 1) {
                    const imageLink = `/production/${selectedProduction.slug}/${day.slug}/${image.name}/selects`;
                    images[count] = {
                        id: image.id,
                        picUrl: `${APP_DEBUG ? BASE_URL_DEBUG : BASE_URL}/display-image?key=${image.publicUrl}&thumb=true`,
                        status: (isKilled === true ? 'killed' : 'selected'),
                        hasImageActiveCaption: image.hasImageActiveCaption,
                        link: imageLink,
                        isSelected: true,
                    };
                    count += 1;
                }
            });
            // return <ImagesList user={user} key={dayIndex} uniqueId={`images-list-${dayIndex}`} dayImages={images} title={day.name} />;
            const dayData = {
                id: parseInt(day.id, 10),
                name: day.name,
                slug: day.slug,
                images,
            };
            killedDays.push(dayData);
        });

        const dayIndex = findIndex(killedDays, (val) => val.slug === params.activeDay);
        let selectedKilledDay = killedDays[0] ? killedDays[0] : {};
        if (dayIndex !== -1) {
            selectedKilledDay = killedDays[dayIndex];
        }

        if (!isFetching) {
            this.setState({
                killedDays,
                selectedKilledDay,
            });
        }
    }

    filterUnkilledImages() {
        const { selectedProduction, user, isFetching } = this.props;
        let currentUser = {};
        const { unkilledDays } = this.state;
        if (!isFetching && !isNull(selectedProduction)) {
            currentUser = selectedProduction.currentUser;
        }

        map(selectedProduction?.productionDays, (day) => {
            const images = [];
            let count = 0;
            each(day.images, (image) => {
                let isKilled = false;
                each(image.views, (view) => {
                    if (parseInt(view.killed, 10) === 1) {
                        if (currentUser.user_type === ROLE_ACTOR && parseInt(user.UID, 10) === parseInt(view.user_id, 10)) {
                            isKilled = true;
                        } else if (currentUser.user_type !== ROLE_ACTOR) {
                            isKilled = true;
                        }
                    }
                });
                if (image.is_selected && parseInt(image.is_selected, 10) === 1 && !isKilled) {
                    const imageLink = `/production/${selectedProduction.slug}/${day.slug}/${image.name}/unkilled-selects`;
                    images[count] = {
                        id: image.id,
                        picUrl: `${APP_DEBUG ? BASE_URL_DEBUG : BASE_URL}/display-image?key=${image.publicUrl}&thumb=true`,
                        status: (isKilled === true ? 'killed' : 'selected'),
                        hasImageActiveCaption: image.hasImageActiveCaption,
                        link: imageLink,
                        isSelected: true,
                    };
                    count += 1;
                }
            });
            // return <ImagesList user={user} key={dayIndex} uniqueId={`images-list-${dayIndex}`} dayImages={images} title={day.name} />;
            const dayData = {
                id: parseInt(day.id, 10),
                name: day.name,
                slug: day.slug,
                images,
            };
            unkilledDays.push(dayData);
        });

        this.setState({
            unkilledDays,
            selectedUnkilledDay: unkilledDays[0] ? unkilledDays[0] : {},
        });
    }

    changeActiveTab(key) {
        const { actions } = this.props;
        actions.saveImagesListPositionRequest(0);

        if (key === 'unkilled-select') {
            this.displayUnkilledTabNotification();
        }

        this.setState({ type: key });
    }

    displayUnkilledTabNotification() {
        notification.open({
            placement: 'bottomLeft',
            description: 'Showing all unkilled selects for days that all actors have marked as done',
            key: 'unkilled-select-notification',
            duration: 7,
        });
    }

    saveCurrentRowPosition(data) {
        const { actions } = this.props;
        if (data.currentRow) {
            actions.saveImagesListPositionRequest(data.currentRow);
        }
    }

    changeActiveKilledDay(dayId) {
        const { killedDays } = this.state;
        const { productionSlug, actions } = this.props;

        const dayIndex = findIndex(killedDays, (val) => val.id === dayId);
        if (dayIndex !== -1) {
            // changing the url on the browser but not actually redirecting
            window.history.replaceState('Selects page', 'Selects', `/production/${productionSlug}/selects/select/${killedDays[dayIndex].slug}`);

            actions.saveImagesListPositionRequest(0);
            this.setState({
                selectedKilledDay: killedDays[dayIndex],
            });
        }
    }

    changeActiveUnkilledDay(dayId) {
        const { unkilledDays } = this.state;
        const { productionSlug, actions } = this.props;

        const dayIndex = findIndex(unkilledDays, (val) => val.id === dayId);
        if (dayIndex !== -1) {
            // changing the url on the browser but not actually redirecting
            window.history.replaceState('Selects page', 'Selects', `/production/${productionSlug}/selects/unkilled-select/${unkilledDays[dayIndex].slug}`);

            actions.saveImagesListPositionRequest(0);
            this.setState({
                selectedUnkilledDay: unkilledDays[dayIndex],
            });
        }
    }

    render() {
        return this.view();
    }
}

Selects.defaultProps = {
    activeTab: 'select',
    activeDay: '',
    productionSlug: '',
    selectedProduction: {},
    user: {},
    params: {},
};

Selects.propTypes = {
    actions: PropTypes.object.isRequired,
    activeTab: PropTypes.string,
    activeDay: PropTypes.string,
    productionSlug: PropTypes.string,
    selectedProduction: PropTypes.object,
    user: PropTypes.object,
    params: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    let activeTab = 'select';
    let activeDay = '';
    let productionSlug = '';

    if (!isUndefined(ownProps.params.activeTab) && ownProps.params.activeTab === 'unkilled-select') {
        activeTab = ownProps.params.activeTab;
    }

    if (!isUndefined(ownProps.params.activeDay)) {
        activeDay = ownProps.params.activeDay;
    }

    if (!isUndefined(ownProps.params.production)) {
        productionSlug = ownProps.params.production;
    }

    return {
        ...ownProps,
        selectedProduction: state.production.selectedProduction,
        user: state.user.user,
        activeTab,
        activeDay,
        productionSlug,
        imagesListPosition: state.global.imagesListPosition,
        isFetching: state.production.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...productionActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Selects);
