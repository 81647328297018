import { Record } from 'immutable';

const {
    SESSION_TOKEN_REQUEST,
    SESSION_TOKEN_SUCCESS,
    SESSION_TOKEN_FAILURE,

    DELETE_TOKEN_REQUEST,
    DELETE_TOKEN_SUCCESS,

    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,

    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,

    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    SIGNUP_FAILURE,

    API_LOGIN_SUCCESS,

    CONTACT_REQUEST,
    CONTACT_SUCCESS,
    CONTACT_FAILURE,

    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAILURE,

    FORGOT_PASSWORD_VALID_LINK_REQUEST,
    FORGOT_PASSWORD_VALID_LINK_SUCCESS,
    FORGOT_PASSWORD_VALID_LINK_FAILURE,

    RESET_USER_PASSWORD_REQUEST,
    RESET_USER_PASSWORD_SUCCESS,
    RESET_USER_PASSWORD_FAILURE,

    USER_SETTINGS_REQUEST,
    USER_SETTINGS_SUCCESS,
    USER_SETTINGS_FAILURE,

    FIRST_TIME_LOGIN_REQUEST,
    FIRST_TIME_LOGIN_SUCCESS,
    FIRST_TIME_LOGIN_FAILURE,

    PASSWORD_RESET_STATE,

} = require('./userActions').constants;

const InitialState = Record({
    disabled: false,
    error: null,
    isFetching: false,
    isLoggedIn: false,
    isLoggedViaApi: false,
    forgotPasswordValid: false,
    user: null,
    isUsername: false,
    resetPasswordSuccess: false,
});

const initialState = new InitialState();

/**
 * ## userReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function userReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case SESSION_TOKEN_REQUEST:
    case FORGOT_PASSWORD_VALID_LINK_REQUEST:
    case SIGNUP_REQUEST:
    case LOGOUT_REQUEST:
    case LOGIN_REQUEST:
    case CONTACT_REQUEST:
    case PASSWORD_RESET_REQUEST:
    case RESET_USER_PASSWORD_REQUEST:
    case USER_SETTINGS_REQUEST:
    case FIRST_TIME_LOGIN_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case SESSION_TOKEN_SUCCESS:
    case SIGNUP_SUCCESS:
        return state.set('isFetching', false)
            .set('isLoggedIn', true);

    case LOGIN_SUCCESS:
    case FIRST_TIME_LOGIN_SUCCESS:
        return state.set('isFetching', false)
            .set('user', payload.data.user)
            .set('isLoggedIn', true);

    case LOGOUT_SUCCESS:
    case RESET_USER_PASSWORD_SUCCESS:
        return state.set('isFetching', false)
            .set('isLoggedIn', false)
            .set('isLoggedViaApi', false);

    case PASSWORD_RESET_SUCCESS:
        return state.set('isFetching', false)
            .set('isLoggedIn', false)
            .set('isLoggedViaApi', false)
            .set('isUsername', payload?.data?.isUsername)
            .set('resetPasswordSuccess', payload?.data?.success);

    case PASSWORD_RESET_STATE:
        return state.set('resetPasswordSuccess', false)
            .set('isUsername', false);

    case API_LOGIN_SUCCESS:
        return state.set('isLoggedViaApi', true);

    case CONTACT_SUCCESS:
    case USER_SETTINGS_SUCCESS:
        return state.set('isFetching', false);

    case SESSION_TOKEN_FAILURE:
    case DELETE_TOKEN_SUCCESS:
        return state.set('isFetching', false)
            .set('isLoggedIn', false);

    case SIGNUP_FAILURE:
    case LOGOUT_FAILURE:
    case LOGIN_FAILURE:
    case CONTACT_FAILURE:
    case FORGOT_PASSWORD_VALID_LINK_FAILURE:
    case PASSWORD_RESET_FAILURE:
    case RESET_USER_PASSWORD_FAILURE:
    case USER_SETTINGS_FAILURE:
    case FIRST_TIME_LOGIN_FAILURE:
        return state.set('isFetching', false)
            .set('forgotPasswordValid', false)
            .set('error', payload);
    case FORGOT_PASSWORD_VALID_LINK_SUCCESS:
        return state.set('isFetching', false)
            .set('forgotPasswordValid', true);
    case DELETE_TOKEN_REQUEST:
        /**
             * no state change, just an ability to track action requests...
             */
        return state;

    default:
        return state;
    }
}
