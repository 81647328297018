/* eslint-disable */
import React from 'react';
import { Tooltip, Empty, Icon, Spin, Alert } from 'antd';
import { size } from 'underscore';
import { Link } from 'react-router';
// import InfiniteScroll from 'react-infinite-scroll-component';
import { FixedSizeGrid as Grid } from 'react-window';

import './imageslist.css';
import { ROLE_ACTOR } from '../../core/utils/value';

export default function () {
    const { dayImages, user, rowHeight, selectedProduction } = this.props;
    const { currentUser } = selectedProduction;

    const FixedListItem = ({ columnIndex, rowIndex, style, data }) => {
        const index = rowIndex * data.numberOfColumns + columnIndex;

        return (
            <div key={`key-${index}`} style={style} className="dayimageWrapper">
                {
                    dayImages[index] ?
                        <div className={`dayimage ${dayImages[index].status}`} data-item-id={dayImages[index].id}>
                            <Link to={dayImages[index].link} className="day-image-link">
                                <img src={dayImages[index].picUrl} alt="" className="dayimage__pic day-image-pic" />
                                <div className="image-single-action-row-small">
                                    {dayImages[index].hasImageActiveCaption == true && currentUser.user_type !== ROLE_ACTOR ?
                                        <div className="image-single-action-icon green">
                                            <Tooltip title="Image has caption">
                                                <Icon theme="filled" type="message" />
                                            </Tooltip>
                                        </div>
                                        :
                                        null
                                    }
                                    {dayImages[index].isSelected == true && currentUser.user_type !== ROLE_ACTOR ?
                                        <div className="image-single-action-icon orange">
                                            <Tooltip title="Image Selected">
                                                <Icon theme="filled" type="star" />
                                            </Tooltip>
                                        </div>
                                        :
                                        null
                                    }
                                    <div className={`image-single-action-icon ${dayImages[index].status == 'killed' ? 'red' : dayImages[index].status == 'removed' ? 'black' : 'grey'}`}>
                                        <Tooltip title={dayImages[index].status === 'killed' ? 'Killed' : dayImages[index].status === 'removed' ? 'Killed and excluded' : dayImages[index].status === 'disabled' ? 'Unviewed' : 'Viewed'}>
                                            <Icon type={dayImages[index].status === 'killed' ? 'close' : dayImages[index].status === 'removed' ? 'close' : dayImages[index].status === 'disabled' ? 'eye-invisible' : 'eye'} />
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="spinnerBox">
                                    <Spin style={{ alignSelf: 'center' }} />
                                </div>
                            </Link>
                        </div>
                    : null

                }
            </div>
        )};

    let numberOfColumns = 4;
    let columnWidth = 148;
    const screenWidth = window.innerWidth;
    const totalImages = size(dayImages);
    let headerSize = 216;
    let currentRowHeight = rowHeight;

    if (screenWidth <= 579 && screenWidth > 400){
        headerSize = 200;
    }

    if (screenWidth <= 640 && screenWidth > 400){
        numberOfColumns = 3;
    } else if (screenWidth <= 400){
        numberOfColumns = 2;
        headerSize = 200;
    }

    if(screenWidth <= 450 && screenWidth > 400) {
        columnWidth = 133;
        currentRowHeight = 133;
    }


    const numberOfRows = Math.ceil(totalImages / numberOfColumns);
    const wholeContainerHeight = window.innerHeight - headerSize;



    // if(this.refs.imagesList){
    //     this.refs.imagesList.scrollToItem({
    //       columnIndex: 0,
    //       rowIndex: 3,
    //     });
    // }

    return (
        <div className="dayimage-wrap">
            {dayImages.length === 0 ?
                <div className="container">
                    <div style={{ textAlign: 'center' }}>
                        <Empty
                            description={<span>No images found</span>} />
                    </div>
                </div>
                :

                <Grid
                    ref="imagesList"
                    className="Grid images-list-grid"
                    columnCount={numberOfColumns}
                    columnWidth={columnWidth}
                    height={wholeContainerHeight}
                    rowCount={numberOfRows}
                    rowHeight={currentRowHeight}
                    style={{ textAlign: 'center' }}
                    width={screenWidth}
                    onScroll={data => this.updateScrollPosition(data)}
                    itemData={{ numberOfColumns }}>
                    {FixedListItem}
                </Grid>
            }
        </div>
    );
}
