import React from 'react';
import { Link } from 'react-router';
import './requestAccess.css';

export default function () {
    return (
        <div className="request-access">
            <p>
                Want to use lmageApprovals.com
                {' '}
                <br />
                {' '}
                for a new production?
            </p>
            <Link to="/request-access" className="ant-btn ant-btn-red">
                Request Access
            </Link>
        </div>
    );
}
