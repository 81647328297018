/* eslint-disable react/no-this-in-sfc */

import React from 'react';
import {
    Row, Col, Empty, Button, Select
} from 'antd';
import { isEmpty } from 'underscore';
import './activeDaySelector.css';

export default function () {
    const { days, onChange, value } = this.props;
    const { Option } = Select;
    const { previousDay, nextDay } = this.state;

    return (
        <div className="activeDaySelectorBlock">
            <div className="container">
                { isEmpty(days)
                    ? (
                        <div style={{ paddingTop: 20 }}>
                            <Empty description={<span>No production days found</span>} />
                        </div>
                    )
                    : (
                        <Row>
                            <Col span={6}>
                                {
                                    previousDay !== 0
                                        ? <Button className="custom-button" icon="left" onClick={() => onChange(previousDay)} />
                                        : null
                                }
                            </Col>
                            <Col span={12} className="middle-col">
                                <Select
                                    showSearch
                                    value={value === 0 ? 'Select a day' : value}
                                    style={{ width: 200 }}
                                    placeholder="Select a day"
                                    optionFilterProp="children"
                                    onChange={onChange}>
                                    {days.map((item) => (
                                        <Option key={`key-${item.id}-time()`} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={6} className="right-col">
                                {
                                    nextDay !== 0
                                        ? <Button className="custom-button" icon="right" onClick={() => onChange(nextDay)} />
                                        : null
                                }
                            </Col>
                        </Row>
                    )}
            </div>
        </div>
    );
}
