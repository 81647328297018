import React from 'react';
import Helmet from 'react-helmet';
import _ from 'underscore';
import { Spin } from 'antd';
import Header from '../../components/Header';
import RequestAccess from '../../components/RequestAccess';
import CardList from '../../components/CardList';
import imagePlaceholder from '../../assets/images/placeholder-image.png';

export default function () {
    const {
        productions, user, resellersList, resellerListFetching, isFetching
    } = this.props;
    let cards = [];
    if (!isFetching) {
        cards = _.map(productions, (production, index) => {
            const temp = {};
            temp.id = production.id;
            temp.name = production.name;
            temp.slug = production.slug;
            temp.picUrl = (production.featured_image !== '' ? production.appMainImage : imagePlaceholder);
            temp.days = production.daysCount;
            temp.reviewed = production.reviewed;
            temp.unreviewed = production.unreviewed;
            temp.killed = production.killed;
            return temp;
        });
    }

    return (
        <Spin spinning={isFetching}>
            <div className="page-wrap production">
                <Helmet title="Production" />
                <Header title="Select a production" handleLogout={this.handleLogout} />
                <CardList
                    cards={cards}
                    user={user}
                    resellersList={resellersList}
                    resellerListFetching={resellerListFetching}
                    filterProductionList={this.filterProductionList} />
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <a href="https://help.imageapprovals.com/">ImageApprovals Help Wiki</a>
            </div>
        </Spin>
    );
}
