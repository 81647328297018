import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    VERIFY_USER_REQUEST: null,
    VERIFY_USER_SUCCESS: null,
    VERIFY_USER_FAILURE: null,

    CONFIRM_S3_UPLOAD_REQUEST: null,
    CONFIRM_S3_UPLOAD_SUCCESS: null,
    CONFIRM_S3_UPLOAD_FAILURE: null,
});

export const {
    verifyUserRequest,
    verifyUserSuccess,
    verifyUserFailure,
} = createActions(
    constants.VERIFY_USER_REQUEST,
    constants.VERIFY_USER_SUCCESS,
    constants.VERIFY_USER_FAILURE,
);

export const {
    confirmS3UploadRequest,
    confirmS3UploadSuccess,
    confirmS3UploadFailure,
} = createActions(
    constants.CONFIRM_S3_UPLOAD_REQUEST,
    constants.CONFIRM_S3_UPLOAD_SUCCESS,
    constants.CONFIRM_S3_UPLOAD_FAILURE,
);

