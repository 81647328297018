import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

const { BUGSNAG_KEY, APP_DEBUG, APP_ENV } = require('../constants').default;

const debug = {};

const getWrapper = (state, version) => {
    if (APP_DEBUG || !BUGSNAG_KEY) {
        return ({ children }) => children;
    }

    /* eslint-disable no-param-reassign */
    const bugsnagClient = bugsnag({
        apiKey: BUGSNAG_KEY,
        beforeSend: (report) => {
            report.user = {
                id: state.user.user.UID,
                name: state.user.user.fullName,
                role: state.user.user.role,
                username: state.user.user.username,
            };
            report.app.releaseStage = APP_ENV;
            report.app.version = version;
        },
    });
    /* eslint-enable */

    bugsnagClient.use(bugsnagReact, React);

    return bugsnagClient.getPlugin('react');
};

debug.getWrapper = getWrapper;

export default debug;
