import React from 'react';
import Helmet from 'react-helmet';
import { Row, Col, Icon } from 'antd';
import { Link } from 'react-router';
import Header from '../components/Header';
import Footer from '../components/Footer';

function NotFound() {
    return (
        <div className="page-wrap">
            <Helmet title="Page not found" />
            <Header title="PAGE NOT FOUND" handleLogout={null} />
            <div className="container">
                <Row style={{ marginTop: '40px' }}>
                    <Col xs={24}>
                        <h2 style={{ textAlign: 'center' }}>
                            <Icon type="exclamation-circle" />
                            {' '}
                            PAGE NOT FOUND
                        </h2>
                    </Col>
                </Row>
                <Row style={{ marginTop: '40px' }}>
                    <Col xs={24} style={{ textAlign: 'center', fontSize: '16px' }}>The link you clicked may be broken or</Col>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                    <Col xs={24} style={{ textAlign: 'center', fontSize: '16px' }}>the page may have been removed.</Col>
                </Row>
                <Row style={{ marginTop: '40px' }}>
                    <Col xs={24} style={{ textAlign: 'center' }}>
                        Please visit
                        <Link to="/">HOMEPAGE</Link>
                        {' '}
                        and try again.
                    </Col>
                </Row>
            </div>
            <Footer />
        </div>
    );
}

export default NotFound;
