import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, autoRehydrate } from 'redux-persist';
import immutableTransform from 'redux-persist-transform-immutable';
import createSagaMiddleware from 'redux-saga';
import localforage from 'localforage';
import { rehydrationCompleted } from './global/globalActions';

/**
* Reducer
*/
import reducer from './reducers';

/**
* Sagas
*/
import rootSagas from './sagas';

// // Configure LocalForage
// localforage.config({
//     name: 'imageApprovals', // Name of the database
//     storeName: 'root', // Name of the data store
//     version: 1.0, // Database version
//     description: 'Local storage for my web app', // Description for the database
//     size: 5 * 1024 * 1024, // Size of the database in bytes (5 MB in this example)
//     driver: [localforage.WEBSQL, localforage.INDEXEDDB, localforage.LOCALSTORAGE], // Preferred storage drivers in order
// });

/**
 * ## configureStore
 * @param initialState
 */
export default function configureStore(initialState) {
    const sagaMiddleware = createSagaMiddleware();
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

    const store = createStore(
        reducer,
        initialState,
        composeEnhancers(autoRehydrate(), applyMiddleware(
            sagaMiddleware,
            thunk,
        )),
    );

    // to backup state in local storage and restore on load
    persistStore(store, {
        key: 'root',
        storage: localforage,
        transforms: [immutableTransform()],
        blacklist: [],
    }, () => {
        store.dispatch(rehydrationCompleted(true));
    });

    sagaMiddleware.run(rootSagas);

    return store;
}
