/* eslint-disable react/no-this-in-sfc */

import React from 'react';
import Helmet from 'react-helmet';
import {
    Row, Col, Input, Button, Form
} from 'antd';
import { Link } from 'react-router';
import Icon from '../../elements/Icon';
import Menu from '../../components/Menu';

export default function () {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
        <div className="page-wrap selectday">
            <Helmet title="Select a day" />
            <div className="header">
                <div className="container">
                    <div className="header__inner">
                        <Row type="flex" justify="space-between" align="middle">
                            <Col span={14}>
                                <div className="flex-center-stretch">
                                    <Menu />
                                    <Link to="/production">
                                        <Icon name="arrowback" className="even-height" />
                                    </Link>
                                    Profile Settings
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className="container">
                <Form onSubmit={this.handleSubmit} layout="vertical">
                    <Row style={{ marginTop: '40px', marginBottom: '10px' }}>
                        <Col xs={24}>
                            <Form.Item label="Current Password">
                                {getFieldDecorator('currentPassword', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please enter current password'
                                        },
                                        {
                                            validator: this.validateToNextPassword,
                                        }
                                    ]
                                })(<Input type="password" />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs={24}>
                            <Form.Item label="New Password">
                                {getFieldDecorator('password', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please enter new password'
                                        },
                                        {
                                            validator: this.validateToNextPassword,
                                        }
                                    ]
                                })(<Input type="password" />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col xs={24}>
                            <Form.Item label="Confirm New Password">
                                {getFieldDecorator('confirmPassword', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please enter confirm new password'
                                        },
                                        {
                                            validator: this.compareToFirstPassword,
                                        }
                                    ]
                                })(<Input type="password" />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} style={{ textAlign: 'center' }}>
                            <Form.Item>
                                <Button type="red" htmlType="submit">
                                    Change Password
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
}
