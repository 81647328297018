/* eslint-disable no-undef */
/* eslint-disable react/no-this-in-sfc */

import React from 'react';
import { Link } from 'react-router';
import _ from 'underscore';
import { Spin, Icon } from 'antd';
import LogoMain from '../LogoMain';
import RequestAccess from '../RequestAccess';
import CustomIcon from '../../elements/Icon';

import './menu.css';
import { ROLE_ACTOR, ROLE_VIEWER } from '../../core/utils/value';

export default function () {
    const { selectedProduction, userProfile, productionUserStats } = this.props;
    if (_.isNull(productionUserStats) || _.isNull(userProfile)) {
        return <Spin />;
    }
    const { menuOpen } = this.state;
    const { currentUser } = productionUserStats;
    if (!currentUser) {
        return null;
    }
    return (
        <div className="menu">
            <button type="button" className="btn-empty" onClick={this.handleMenuClick} label="Menu">
                <CustomIcon name="menu" className="even-height" />
            </button>
            <div className={`menu__wrap ${menuOpen}`}>
                <span className="menu__backdrop" onClick={this.handleMenuBackdropClick} />
                <div className="menu__inner">
                    <div className="container">
                        <button type="button" className="btn-empty menu__close" onClick={this.handleMenuClick} label="Close">
                            <CustomIcon name="close" className="even-height" />
                        </button>
                        <LogoMain color="white" width="183px" />
                        <p className="menu__user">
                            Welcome,
                            {' '}
                            {userProfile.fullName}
                        </p>
                        <div className="menu__production">
                            <p className="menu__label">Currently reviewing images for</p>
                            <div className="menu__change">
                                <p className="text-semibold">{selectedProduction ? selectedProduction.name : ''}</p>
                                <div>
                                    <Link className="link-red" to="/production">
                                        <CustomIcon name="change" className="even-height" />
                                        Change
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <nav className="menu__nav">
                            <ul className="menu__navlist">
                                {currentUser.user_type !== ROLE_VIEWER
                                    ? (
                                        <li>
                                            <Link className="menu__navlink" to={`/production/${selectedProduction ? selectedProduction.slug : ''}`}>
                                                <div className="menu__nav-icon">
                                                    <CustomIcon name="eye" className="even-height" />
                                                </div>
                                                <span className="menu__navlink-text">Review images</span>
                                            </Link>
                                        </li>
                                    ) : (
                                        <li>
                                            <Link className="menu__navlink" to={`/production/${selectedProduction ? selectedProduction.slug : ''}`}>
                                                <div className="menu__nav-icon">
                                                    <CustomIcon name="eye" className="even-height" />
                                                </div>
                                                <span className="menu__navlink-text">View images</span>
                                            </Link>
                                        </li>
                                    )}
                                {currentUser.user_type !== ROLE_ACTOR && currentUser.user_type !== ROLE_VIEWER
                                    ? (
                                        <li>
                                            <Link className="menu__navlink" to={`/production/${selectedProduction ? selectedProduction.slug : ''}/kill-list`} onClick={() => setTimeout(() => window.location.reload(), 200)}>
                                                <div className="menu__nav-icon">
                                                    <CustomIcon name="close" className="even-height" />
                                                </div>
                                                <span className="menu__navlink-text">Killed And Unkilled</span>
                                            </Link>
                                        </li>
                                    ) : null}
                                {currentUser.user_type === ROLE_ACTOR
                                    ? (
                                        <li>
                                            <Link className="menu__navlink" to={`/production/${selectedProduction ? selectedProduction.slug : ''}/kill-list`} onClick={() => setTimeout(() => window.location.reload(), 200)}>
                                                <div className="menu__nav-icon">
                                                    <CustomIcon name="close" className="even-height" />
                                                </div>
                                                <span className="menu__navlink-text">Killed Images</span>
                                            </Link>
                                        </li>
                                    ) : null}
                                {currentUser.user_type !== ROLE_ACTOR && currentUser.user_type !== ROLE_VIEWER
                                    ? (
                                        <li>
                                            <Link className="menu__navlink" to={`/production/${selectedProduction ? selectedProduction.slug : ''}/downloads`}>
                                                <div className="menu__nav-icon">
                                                    <Icon type="download" style={{ fontSize: 26, color: '#c00000' }} />
                                                </div>
                                                <span className="menu__navlink-text">Downloads</span>
                                            </Link>
                                        </li>
                                    )
                                    : null}
                                {currentUser.user_type !== ROLE_ACTOR && currentUser.user_type !== ROLE_VIEWER
                                    ? (
                                        <li>
                                            <Link className="menu__navlink" to={`/production/${selectedProduction ? selectedProduction.slug : ''}/selects`}>
                                                <div className="menu__nav-icon">
                                                    <Icon type="star" style={{ fontSize: 26, color: '#c00000' }} />
                                                </div>
                                                <span className="menu__navlink-text">Selects</span>
                                            </Link>
                                        </li>
                                    )
                                    : null}
                                <li>
                                    <Link className="menu__navlink" to="/settings">
                                        <div className="menu__nav-icon">
                                            <Icon type="setting" style={{ fontSize: 26, color: '#c00000' }} />
                                        </div>
                                        <span className="menu__navlink-text">Settings</span>
                                    </Link>
                                </li>
                                <li>
                                    <a className="menu__navlink" href="https://help.imageapprovals.com/" rel="noopener noreferrer" target="_blank">
                                        <div className="menu__nav-icon">
                                            <Icon type="question-circle" style={{ fontSize: 26, color: '#c00000' }} />
                                        </div>
                                        <span className="menu__navlink-text">Help</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <div className="menu__copy">
                            <RequestAccess />
                            <Link className="link-red menu__logout" onClick={this.handleLogout}>
                                Log out
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
