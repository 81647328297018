import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Form } from 'antd';
import { connect } from 'react-redux';
import * as userActions from '../../core/user/userActions';
import DOM from './passwordReset';

class PasswordRestForm extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            confirmDirty: false,
        };
    }

    componentDidMount() {
        const { actions, params } = this.props;
        actions.forgotPasswordValidLinkRequest({
            token: params.token,
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { actions, params } = this.props;
                actions.resetUserPasswordRequest({
                    token: params.token,
                    password: values.password,
                });
            }
        });
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirmPassword'], { force: true });
        }
        callback();
    }

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Passwords do not match!');
        } else {
            callback();
        }
    }

    render() {
        return this.view();
    }
}

PasswordRestForm.defaultProps = {
    params: {},
};

PasswordRestForm.propTypes = {
    actions: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    params: PropTypes.object,
};

const PasswordReset = Form.create()(PasswordRestForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        user: state.user.user,
        forgotPasswordValid: state.user.bool,
        isFetching: state.user.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...userActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PasswordReset);
