import { Record } from 'immutable';
import alertify from 'alertify.js';

const {
    REHYDRATION_COMPLETED,
    SET_VERSION,
    SET_BREADCRUMB,

    SAVE_IMAGES_LIST_POSITION_REQUEST,

    TOGGLE_IMAGE_CAPTION_MODE_REQUEST,
} = require('./globalActions').constants;

const InitialState = Record({
    version: null,
    breadcrumb: [],
    rehydrationCompleted: false,
    isFetching: false,
    imagesListPosition: 0,
    imageCaptionModeOn: false,
});

const initialState = new InitialState();
/**
 * ## globalReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function globalReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {
    case SET_VERSION:
        return state.set('version', payload);

    case SET_BREADCRUMB:
        return state.set('breadcrumb', payload);

    case REHYDRATION_COMPLETED:
        return state.set('rehydrationCompleted', payload);

    case SAVE_IMAGES_LIST_POSITION_REQUEST:
        return state.set('imagesListPosition', payload);

    case TOGGLE_IMAGE_CAPTION_MODE_REQUEST:

        if (payload) {
            alertify.success('Captioning mode enabled');
        } else {
            alertify.success('Captioning mode disabled');
        }

        return state.set('imageCaptionModeOn', payload);

    default:
        return state;
    }
}
