import React from 'react';
import { Row, Col } from 'antd';
import { isEmpty } from 'underscore';

import './kill-statistics.css';

export default function () {
    const { productionUserStats } = this.props;

    if (isEmpty(productionUserStats)) {
        return null;
    }

    const { currentUser } = productionUserStats;
    const { imageCountByTagNumber } = productionUserStats.totalProductionImages;

    let limit = 0;
    let countByTagNum = 0;
    let lowerGroupTotal = 0;
    let upperGroupTotal = 0;

    // If user is not actor, don't show this section
    if (!currentUser || currentUser.user_type !== 4) {
        return null;
    }

    if (currentUser.allowance_type > 1) {
        limit = parseInt(currentUser.upper_group_threshold, 10);
        countByTagNum = Object.keys(imageCountByTagNumber).map((key) => ({ key: parseInt(key, 10), value: imageCountByTagNumber[key] }));
        lowerGroupTotal = countByTagNum.reduce((acc, v) => (acc + (v.key < limit && v.key > 1 ? v.value : 0)), 0);
        upperGroupTotal = countByTagNum.reduce((acc, v) => (acc + (v.key >= limit ? v.value : 0)), 0);
    }

    return (
        <div className="killstats">
            <div className="container">
                <Row>
                    <Col span={9}>
                        <div className="killstats__item">
                            <span className="text-bold">
                                {currentUser.soloKills}
                                {' '}
                                of
                                {' '}
                                {currentUser.soloKillLimit === 0
                                    ? 'Unlimited'
                                    : Math.ceil((currentUser.soloKillLimit / 100) * productionUserStats.totalProductionImages.soloImages)}
                            </span>
                            <br />
                            {currentUser.soloKillLimit > 0
                                ? (
                                    <span className="text-small">
                                        (
                                        {Math.ceil(currentUser.soloKills / (currentUser.soloKillLimit === 0
                                            ? 1
                                            : Math.ceil((currentUser.soloKillLimit / 100) * productionUserStats.totalProductionImages.soloImages)) * 100)}
                                        % used)
                                    </span>
                                )
                                : null}
                        </div>
                    </Col>
                    <Col span={15}>
                        <div className="killstats__item">
                            <p>The number of solo kills you have made for this production</p>
                        </div>
                    </Col>
                </Row>
                {currentUser.allowance_type === 1 ? (
                    <Row>
                        <Col span={9}>
                            <div className="killstats__item">
                                <span className="text-bold">
                                    {currentUser.groupKills}
                                    {' '}
                                    of
                                    {' '}
                                    {
                                        currentUser.groupKillLimit === 0
                                            ? 'Unlimited'
                                            : Math.ceil((currentUser.groupKillLimit / 100) * productionUserStats.totalProductionImages.groupImages)
                                    }
                                </span>
                                <br />
                                {currentUser.groupKillLimit > 0 ? (
                                    <span className="text-small">
                                        (
                                        {Math.ceil(currentUser.groupKills / (currentUser.groupKillLimit === 0
                                            ? 1
                                            : Math.ceil((currentUser.groupKillLimit / 100) * productionUserStats.totalProductionImages.groupImages)) * 100)}
                                        % used)
                                    </span>
                                )
                                    : null}
                            </div>
                        </Col>
                        <Col span={15}>
                            <div className="killstats__item">
                                <p>The number of group kills you have made for this production</p>
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col span={9}>
                            <div className="killstats__item">
                                <span className="text-bold">
                                    {currentUser.groupKills}
                                    {' '}
                                    of
                                    {
                                        currentUser.groupKillLimit === 0
                                            ? 'Unlimited'
                                            : Math.ceil((currentUser.groupKillLimit / 100) * lowerGroupTotal)
                                    }
                                </span>
                                <br />

                                {currentUser.groupKillLimit > 0 ? (
                                    <span className="text-small">
                                        (
                                        {Math.ceil(currentUser.groupKills / (currentUser.groupKillLimit === 0
                                            ? 1
                                            : Math.ceil((currentUser.groupKillLimit / 100) * lowerGroupTotal)) * 100)}
                                        % used)
                                    </span>
                                )
                                    : null}
                            </div>
                        </Col>
                        <Col span={15}>
                            <div className="killstats__item">
                                <p>The number of lower group kills you have made for this production</p>
                            </div>
                        </Col>
                    </Row>
                )}

                {currentUser.allowance_type === 1 ? null : (
                    <Row>
                        <Col span={9}>
                            <div className="killstats__item">
                                <span className="text-bold">
                                    {currentUser.upperGroupKills}
                                    {' '}
                                    of
                                    { currentUser.upperGroupKillLimit === 0
                                        ? 'Unlimited'
                                        : Math.ceil((currentUser.upperGroupKillLimit / 100) * upperGroupTotal)}
                                </span>
                                <br />
                                {currentUser.upperGroupKillLimit > 0 ? (
                                    <span className="text-small">
                                        (
                                        {Math.ceil(currentUser.upperGroupKills / (currentUser.upperGroupKillLimit === 0
                                            ? 1
                                            : Math.ceil((currentUser.upperGroupKillLimit / 100) * upperGroupTotal)) * 100)}
                                        % used)
                                    </span>
                                )
                                    : null}
                            </div>
                        </Col>
                        <Col span={15}>
                            <div className="killstats__item">
                                <p>The number of upper group kills you have made for this production</p>
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
}
