import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';
import * as userActions from '../../core/user/userActions';

import DOM from './profile';

class SettingsForm extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            confirmDirty: false,
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { actions } = this.props;
                actions.userSettingsRequest({
                    currentPassword: values.currentPassword,
                    password: values.password,
                });
            }
        });
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirmPassword'], { force: true });
        }
        callback();
    }

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Passwords do not match!');
        } else {
            callback();
        }
    }

    render() {
        return this.view();
    }
}

SettingsForm.propTypes = {
    actions: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
};

const Profile = Form.create()(SettingsForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.user.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...userActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Profile);
