import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'underscore';
import { Modal } from 'antd';
import * as globalActions from '../../core/global/globalActions';
import DOM from './reviewDay';
import * as productionActions from '../../core/production/productionActions';

class ReviewDay extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            currentTab: 'all',
            visibleProductionInfo: false,
        };
    }

    componentDidMount() {
        const { actions, params, selectedProduction } = this.props;
        actions.syncLocalImagesRequest();
        const shouldGetProduction = _.isNull(selectedProduction) || _.isUndefined(selectedProduction.productionDays);
        // if (shouldGetProduction) {
        actions.getUserSingleProductionRequest({
            slug: params.production,
        });
        // }
        // actions.getUserSingleProductionDayRequest({
        //     productionSlug: params.production,
        //     daySlug: params.day,
        // });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.params !== nextProps.params) {
            const { actions, selectedProduction } = this.props;
            const shouldGetProduction = _.isNull(selectedProduction) || _.isUndefined(selectedProduction.productionDays);
            // if (shouldGetProduction) {
            actions.getUserSingleProductionRequest({
                slug: nextProps.params.production,
            });
            // }
            // actions.getUserSingleProductionDayRequest({
            //     productionSlug: nextProps.params.production,
            //     daySlug: nextProps.params.day,
            // });
        }
    }

    handleProductionInfoOk = () => {

    }

    handleProductionInfoCancel = () => {
        this.setState({
            visibleProductionInfo: false,
        });
    }

    showConfirm = () => {
        const { actions, params } = this.props;
        Modal.confirm({
            title: 'Are you sure you want to mark this day as done?',
            content: 'This will lock in your kills for this set of images.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                actions.userMarkDayDoneRequest({
                    productionSlug: params.production,
                    daySlug: params.day,
                });
            },
        });
    }

    render() {
        return this.view({ global });
    }
}

ReviewDay.defaultProps = {
    params: {},
};

ReviewDay.propTypes = {
    actions: PropTypes.object.isRequired,
    params: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedProduction: state.production.selectedProduction,
        selectedProductionDay: state.production.selectedProductionDay,
        user: state.user.user,
        isFetching: state.production.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...productionActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReviewDay);
