/* eslint-disable react/no-this-in-sfc */

import React from 'react';
import Helmet from 'react-helmet';
import {
    Table, Row, Col, Icon, Alert, Spin
} from 'antd';
import moment from 'moment';
import _ from 'underscore';
import Menu from '../../components/Menu';

export default function () {
    const { downloadQueue, params, isFetching } = this.props;

    const reportNames = [
        'All Images',
        'Un-Killed Images',
        'Killed Images',
        'All selects images',
        'Un-Killed selects images',
        'Killed selects images',
    ];

    const columns = [{
        title: 'Type',
        dataIndex: 'list_type',
        key: 'list_type',
        render: (list_type) => {
            if (list_type >= 0 && list_type < reportNames.length) {
                return (
                    <span>
                        {reportNames[list_type]}
                        {' '}
                        list
                    </span>
                );
            }
            return <span>(Not-Set)</span>;
        }
    }, {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
            switch (status) {
            case 0:
                return <span>Pending</span>;
            case 1:
                return <span>Processing</span>;
            case 2:
                return <span>Finished</span>;
            default:
                return <span>(Not-Set)</span>;
            }
        }
    }, {
        title: 'Generated On',
        key: 'generated_date',
        dataIndex: 'generated_date',
        render: (generated_date) => <span>{!_.isNull(generated_date) ? moment(generated_date, 'YYYY-MM-DD HH:ii:ss').format('DD/MM/YYYY HH:mm:ss') : ''}</span>
    }, {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
            <span>
                {parseInt(record.status, 10) === 2
                    ? (
                        <span>
                            <a onClick={() => this.downloadFile(record.list_type, record.file, 'pdf', 'download')}>
                                <Icon type="download" />
                                {' '}
                                Download
                            </a>
                        </span>
                    )
                    : null}
            </span>
        ),
    }];

    const renderPDFDownload = (number) => (
        <Row>
            <Col xs={2} />
            <Col xs={18}>{`... ${reportNames[number]} report`}</Col>
            <Col xs={4}>
                <a onClick={() => this.downloadFile(number, 'none', 'pdf', 'generate')}>
                    <Icon type="file-pdf" />
                    {' '}
                    Request
                </a>
            </Col>
        </Row>
    );

    const renderXSLXDownload = (number) => {
        const reportName = reportNames[number].toLowerCase().replace(/\W/g, '-');
        return (
            <Row>
                <Col xs={2} />
                <Col xs={18}>{`... ${reportNames[number]} report`}</Col>
                <Col xs={4}>
                    <a onClick={() => this.downloadFile(number, `${params.production}-${reportName}.xlsx`, 'xlsx', 'download')}>
                        <Icon type="file-excel" />
                        {' '}
                        Request
                    </a>
                </Col>
            </Row>
        );
    };

    return (
        <div className="page-wrap selectday">
            <Helmet title="Select a day" />
            <div className="header">
                <div className="container">
                    <div className="header__inner">
                        <Row type="flex" justify="space-between" align="middle">
                            <Col span={16}>
                                <div className="flex-center-stretch">
                                    <Menu />
                                    Download PDF
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className="container downloads-list">
                <Spin spinning={isFetching}>
                    <Row>
                        <Col xs={24}><h2>Request a new report</h2></Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xs={24}><h3>PDF Reports</h3></Col>
                    </Row>

                    {renderPDFDownload(0)}
                    {renderPDFDownload(2)}
                    {renderPDFDownload(1)}
                    {renderPDFDownload(3)}
                    {renderPDFDownload(5)}
                    {renderPDFDownload(4)}

                    <Row style={{ marginTop: '20px' }}>
                        <Col xs={24}><h3>XSLX Reports</h3></Col>
                    </Row>

                    {renderXSLXDownload(0)}
                    {renderXSLXDownload(2)}
                    {renderXSLXDownload(1)}
                    {renderXSLXDownload(3)}
                    {renderXSLXDownload(5)}
                    {renderXSLXDownload(4)}

                    <Row style={{ marginTop: '40px' }}>
                        <Col xs={24}>
                            <Alert message="Generated pdf file(s) will be automatically deleted after 24 hours." type="info" />
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '20px' }}>
                        <Col xs={24}>
                            <Table
                                pagination={false}
                                columns={columns}
                                dataSource={downloadQueue} />
                        </Col>
                    </Row>
                </Spin>
            </div>
        </div>
    );
}
