/* global window: true */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { size } from 'underscore';
import * as globalActions from '../../core/global/globalActions';
import * as productionActions from '../../core/production/productionActions';
import DOM from './imagesList';

class ImagesList extends React.Component {
    constructor(props) {
        super(props);

        const imagesLenght = size(props.dayImages);
        let hasMore = false;
        let arraySize = 8;

        if (arraySize > imagesLenght) {
            arraySize = imagesLenght;
        }

        if (imagesLenght > arraySize) {
            hasMore = true;
        }

        this.state = {
            items: props.dayImages.slice(0, arraySize),
            hasMore,
        };
        this.view = DOM;
    }

    componentDidMount() {
        const { imagesListPosition } = this.props;

        // this will allow to scroll to the desired position of the list
        if (this.refs.imagesList && imagesListPosition !== 0) {
            this.refs.imagesList.scrollToItem({
                align: 'start',
                rowIndex: imagesListPosition,
            });
        }
    }

    fetchMoreData = () => {
        const { dayImages } = this.props;
        const { items } = this.state;
        const stepSize = 12;
        let nextLoadItemsLenght = stepSize;
        const dayImagesSize = size(dayImages);
        const itemsSize = size(items);

        if (itemsSize + stepSize > dayImagesSize) {
            nextLoadItemsLenght = dayImagesSize - itemsSize;
        }

        if (itemsSize >= dayImagesSize) {
            this.setState({ hasMore: false });
            return;
        }

        setTimeout(() => {
            const newItems = dayImages.slice(itemsSize, itemsSize + nextLoadItemsLenght);
            const pushedItems = items.concat(newItems);

            this.setState({
                items: pushedItems,
            });
        }, 500);
    };

    updateScrollPosition(data) {
        const { rowHeight, onChange } = this.props;
        const screenWidth = window.innerWidth;
        let currentRow = 0;
        let currentRowHeight = rowHeight;

        if (screenWidth <= 450 && screenWidth > 400) {
            currentRowHeight = 133;
        }

        if (data.scrollTop && data.verticalScrollDirection) {
            let continueUpdate = true;

            if (data.scrollTop === 0 && data.verticalScrollDirection === 'forward') {
                continueUpdate = false;
            }

            if (continueUpdate) {
                currentRow = Math.ceil(data.scrollTop / currentRowHeight);
                onChange({ currentRow });
            }
        }
    }

    render() {
        return this.view(this.props);
    }
}
ImagesList.defaultProps = {
    title: '',
    dayImages: [],
    blockHeight: 500,
    rowHeight: 151,
    imagesListPosition: 0,
    onChange: () => {},
};

ImagesList.propTypes = {
    title: PropTypes.string,
    dayImages: PropTypes.array,
    blockHeight: PropTypes.number,
    rowHeight: PropTypes.number,
    imagesListPosition: PropTypes.number,
    onChange: PropTypes.func,
};
function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedProduction: state.production.selectedProduction,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...productionActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ImagesList);
