import React from 'react';
import { Link } from 'react-router';
import LogoMain from '../LogoMain';

import './footer.css';

export default function () {
    const currentYear = new Date().getFullYear();
    return (
        <div className="footer">
            <div className="container">
                <div className="footer__inner">
                    <LogoMain color="white" width="140px" />
                    <div className="footer__copy">
                        <p>Copyright {currentYear} ImageApprovals</p>
                        <span className="separator" />
                        <Link
                            to="https://help.imageapprovals.com/"
                            rel="noopener noreferer"
                            target="_blank"
                            className="link-red">
                            Help Wiki
                        </Link>
                        <span className="separator" />
                        <Link
                            to="terms-and-conditions.pdf"
                            rel="noopener noreferer"
                            target="_blank"
                            className="link-red">
                            Terms and Conditions
                        </Link>
                        <span className="separator" />
                        <Link to="/request-access">
                            Request Quote For a New Production
                        </Link>
                        {/* <Link className="link-red" to="terms-and-conditions">
                            terms and conditions
                        </Link> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
