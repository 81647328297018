import React from 'react';
import Helmet from 'react-helmet';
import {
    Tabs, Row, Col, Empty,
    Spin
} from 'antd';
import _ from 'underscore';
import ImagesList from '../../components/ImagesList';
import Menu from '../../components/Menu';
import AllowanceWidget from '../../components/AllowanceWidget';
import ActiveDaySelector from '../../components/ActiveDaySelector';

const { TabPane } = Tabs;

export default function () {
    const { user, activeTab, imagesListPosition, isFetching } = this.props;
    const {
        selectedKilledDay, killedDays, unkilledDays, selectedUnkilledDay
    } = this.state;

    const uniqueKilledDays = _.uniq(killedDays, (item) => item.id);
    const uniqueUnkilledDays = _.uniq(unkilledDays, (item) => item.id);

    return (
        <div className="page-wrap selectday">
            <Helmet title="Select a day" />
            <div className="header">
                <div className="container">
                    <div className="header__inner">
                        <Row type="flex" justify="space-between" align="middle">
                            <Col span={14}>
                                <div className="flex-center-stretch">
                                    <Menu />
                                    Selects
                                </div>
                            </Col>
                            <Col span={10}>
                                <AllowanceWidget />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <Tabs defaultActiveKey={activeTab} className="ant-tabs--styled" onChange={(key) => this.changeActiveTab(key)}>
                <TabPane tab="Selects" key="select">
                    
                    { _.isEmpty(killedDays)
                        ? (
                            isFetching ? (
                                <div style={{ textAlign: 'center', paddingTop: 20 }}>
                                    <Spin />
                                </div>
                            ) : (
                                <div style={{ paddingTop: 20, textAlign: 'center' }}>
                                    <Empty description={<span>No production days found</span>} />
                                </div>
                            )
                        )
                        : (
                            <div>
                                <ActiveDaySelector days={uniqueKilledDays} onChange={this.changeActiveKilledDay.bind(this)} value={selectedKilledDay && selectedKilledDay.id ? selectedKilledDay.id : 0} />
                                {_.isEmpty(selectedKilledDay)
                                    ? (
                                        <div className="container">
                                            <div style={{ paddingTop: 20 }}>
                                                <Empty
                                                    description={<span>No images found</span>} />
                                            </div>
                                        </div>
                                    )
                                    : <ImagesList key={selectedKilledDay.id} uniqueId={`images-list-${selectedKilledDay.id}`} dayImages={selectedKilledDay.images} title={selectedKilledDay.name} user={user} onChange={(data) => this.saveCurrentRowPosition(data)} imagesListPosition={imagesListPosition} />}
                            </div>
                        )}
                </TabPane>
                <TabPane tab="Unkilled selects" key="unkilled-select">
                    { _.isEmpty(unkilledDays)
                        ? (
                            isFetching ? (
                                <div style={{ textAlign: 'center', paddingTop: 20 }}>
                                    <Spin />
                                </div>
                            ) : (
                                <div style={{ paddingTop: 20, textAlign: 'center' }}>
                                    <Empty description={<span>No production days found</span>} />
                                </div>
                            )
                        )
                        : (
                            <div>
                                <ActiveDaySelector days={uniqueUnkilledDays} onChange={this.changeActiveUnkilledDay.bind(this)} value={selectedUnkilledDay && selectedUnkilledDay.id ? selectedUnkilledDay.id : 0} />
                                {_.isEmpty(selectedUnkilledDay)
                                    ? (
                                        <div className="container">
                                            <div style={{ paddingTop: 20 }}>
                                                <Empty
                                                    description={<span>No images found</span>} />
                                            </div>
                                        </div>
                                    )
                                    : <ImagesList key={selectedUnkilledDay.id} uniqueId={`images-list-${selectedUnkilledDay.id}`} dayImages={selectedUnkilledDay.images} title={selectedUnkilledDay.name} user={user} blockHeight={450} onChange={(data) => this.saveCurrentRowPosition(data)} imagesListPosition={imagesListPosition} />}
                            </div>
                        )}
                </TabPane>
            </Tabs>
        </div>
    );
}
