import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'underscore';
import * as globalActions from '../../core/global/globalActions';
import * as productionActions from '../../core/production/productionActions';
import DOM from './selectDayList';

class SelectDayList extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    selectActor = (value) => {
        const { actions } = this.props;
        if (!_.isUndefined(value)) {
            const temp = value.split('-');
            actions.setSelectedActor({ id: temp[0], name: temp[1] });
        }
    }

    render() {
        return this.view();
    }
}
SelectDayList.defaultProps = {
    days: {},
};

SelectDayList.propTypes = {
    actions: PropTypes.object.isRequired,
    days: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedProduction: state.production.selectedProduction,
        isFetching: state.production.isFetching,
        user: state.user.user,
        selectedActor: state.production.selectedActor,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...productionActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectDayList);
