import React from 'react';
import { Link } from 'react-router';
import {
    Form, Input, Button, Row, Col
} from 'antd';
import LogoMain from '../LogoMain';
import RequestAccess from '../RequestAccess';
import './loginform.css';
import Loading from '../../elements/Loading';

const FormItem = Form.Item;

export default function () {
    const { getFieldDecorator } = this.props.form;
    const { isFetching } = this.props;

    return (
        <div className="loginform-wrap">
            <div className="loginform-container">
                { isFetching ? <Loading /> : null }
                <LogoMain width="295px" />
                <div className="loginform-backdrop">
                    <Form onSubmit={this.handleSubmit} layout="vertical">
                        <FormItem label="Email or Username">
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input your E-mail or Username'
                                    }
                                ]
                            })(<Input />)}
                        </FormItem>
                        <FormItem label="Password">
                            {getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input your password!'
                                    }
                                ]
                            })(<Input type="password" />)}
                        </FormItem>
                        <Row className="loginform__agree" style={{ marginBottom: 0 }}>
                            <Col sm={6} xs={1} />
                            <Col sm={12} xs={22}>
                                <p>By logging in you agree to our</p>
                            </Col>
                            <Col sm={6} xs={1} />
                        </Row>
                        <Row style={{ marginBottom: '15px' }}>
                            <Col sm={8} xs={1} />
                            <Col sm={8} xs={22}>
                                <a href="terms-and-conditions.pdf" rel="noopener noreferer" target="_blank" className="link-red">
                                    terms and conditions
                                </a>
                                {/* <Link className="link-red" to="terms-and-conditions">
                                    terms and conditions
                                </Link> */}
                            </Col>
                            <Col sm={8} xs={1} />
                        </Row>
                        <FormItem>
                            <Button type="red" htmlType="submit">
                                Agree & Log in
                            </Button>
                        </FormItem>
                        <Row>
                            <Col sm={8} xs={1} />
                            <Col sm={8} xs={22}>
                                <Link className="link-red loginform__forgotten" to="/forgot-password">
                                    Forgotten password
                                </Link>
                            </Col>
                            <Col sm={8} xs={1} />
                        </Row>
                    </Form>
                </div>
                <RequestAccess />
            </div>
        </div>
    );
}
