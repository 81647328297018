/* global document: true */
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import lockr from 'lockr';
import 'antd/dist/antd.css';
import { purgeStoredState } from 'redux-persist';
import { asyncSessionStorage } from 'redux-persist/storages';
// Svg Polyfill For IE
import svg4everybody from 'svg4everybody';
// end Svg Polyfill For IE

import AdminLayout from './components/AdminLayout';
import EmptyLayout from './components/EmptyLayout';
import RequestAccess from './pages/RequestAccess';
import Login from './pages/Login';
import Production from './pages/Production';
import SelectDay from './pages/SelectDay';
import ReviewDay from './pages/ReviewDay';
import KillList from './pages/KillList';
import ReviewImage from './pages/ReviewImage';
import NotFound from './pages/NotFound';
import ForgotPassword from './pages/ForgotPassword';
import PasswordReset from './pages/PasswordReset';
import Downloads from './pages/Downloads';
import Profile from './pages/Profile';
import Selects from './pages/Selects';

import bugsnag from './core/utils/bugsnag';

import './styles/style.css';

/**
 * ## Actions
 *  The necessary actions for dispatching our bootstrap values
 */
import { setVersion } from './core/global/globalActions';

/**
 *  The version of the app but not displayed yet
 */
import pack from '../package.json';

/**
 * ### configureStore
 */
import configureStore from './core/store';
import UploadLinks from './pages/UploadLinks';

svg4everybody();
const store = configureStore({});
const VERSION = pack.version;
const { APP_VERSION } = require('./core/constants').default;

const previousVersion = lockr.get(APP_VERSION);
if (previousVersion !== VERSION) {
    // remove state from local storage
    purgeStoredState({ storage: asyncSessionStorage });

    lockr.flush();
}
store.dispatch(setVersion(VERSION));
lockr.set(APP_VERSION, VERSION);

const scrollToTop = () => {
    const pusher = document.getElementById('app');
    if (pusher !== null) {
        pusher.scrollIntoView();
    }
};

const ErrorBoundary = bugsnag.getWrapper(VERSION, store.getState());

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <Router history={browserHistory} onUpdate={scrollToTop}>
                <Route component={EmptyLayout}>
                    <Route path="/" component={Login} />
                    <Route path="login(/:autoLoginKey)" component={Login} />
                    <Route path="request-access" component={RequestAccess} />
                    <Route path="forgot-password" component={ForgotPassword} />
                    <Route path="password-reset/:token" component={PasswordReset} />
                    <Route path="uploader/:slug" component={UploadLinks} />
                </Route>
                <Route component={AdminLayout}>
                    <Route path="production" component={Production} />
                    <Route path="production/:production" component={SelectDay} />
                    <Route path="production/:production/kill-list(/:activeTab)(/:activeDay)" component={KillList} />
                    <Route path="production/:production/selects(/:activeTab)(/:activeDay)" component={Selects} />
                    <Route path="production/:production/downloads" component={Downloads} />
                    <Route path="production/:production/:day" component={ReviewDay} />
                    <Route path="production/:production/:day/:image(/:status)" component={ReviewImage} />
                    <Route path="settings" component={Profile} />
                </Route>
                <Route path="*" component={NotFound} />
            </Router>
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root'),
);
